import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { CVsTable, InfoBox, IssueBox, LITable, QueryBox, SuggestionBox } from '../acm/components';
import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';
import QuillEditor from '../acm/components/QuilEditor';
import { IsParsable } from 'src/helpers';
import { LeaveRole, MarkRoleAsDoneDialog } from './components';

function ResourcingRolesProcessing(props) {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');
  const [role, setRole] = useState();

  const [queryString, setQueryString] = useState();
  const [info, setInfo] = useState([]);
  const [queries, setQueries] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [issues, setIssues] = useState([]);

  const [maximizeIssueBox, setMaximizeIssueBox] = useState(false);
  const [maximizeInfoBox, setMaximizeInfoBox] = useState(false);
  const [maximizeSuggestionBox, setMaximizeSuggestionBox] = useState(false);
  const [maximizeQueryBox, setMaximizeQueryBox] = useState(false);
  const [roleCVs, setRoleCVs] = useState([]);

  const [openMarkDoneDialog, setOpenMarkDoneDialog] = useState(false);
  const [openLeaveRoleDialog, setOpenLeaveRoleDialog] = useState(false);
  const [cvPage, setCvPage] = useState(0);
  const [cvLimit, setCvLimit] = useState(5);
  const [cvTotalCoount, setCvTotalCoount] = useState(0);

  const [roleLis, setRoleLis] = useState([]);
  const [liPage, setLiPage] = useState(0);
  const [liLimit, setLiLimit] = useState(5);
  const [liTotalCount, setLiTotalCount] = useState(0);
  const roleOptions = [
    {
      id: 'markdone',
      label: 'Mark Done',
    },
    {
      id: 'leaverole',
      label: 'Leave Role',
    },
  ];

  // Initialize state with the first option's value
  const [selectedRole, setSelectedRole] = useState(roleOptions[0]?.id);
  const [openStartRoleConfirmationDialog, setOpenStartRoleConfirmationDialog] = useState(false);
  const handleOptionChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const getCVsOfRole = useCallback(() => {
    // alert('getCVsOfRole');
    try {
      Get(
        { roleId: roleId, page: cvPage, limit: cvLimit },
        API_URLS.getRoleCVs,
        (resp) => {
          console.log(resp);
          setRoleCVs(resp?.data?.data);
          setCvTotalCoount(resp?.data?.pagination?.totalCVs);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [roleId, cvPage, cvLimit]);
  useEffect(() => {
    getCVsOfRole();
  }, [getCVsOfRole]);
  const getRoleDetails = () => {
    try {
      Get(
        { id: roleId },
        API_URLS.getRoleById,
        (response) => {
          console.log(response);
          setRole(response?.data?.role);

          // Initialize arrays for all data types
          const newInfo = [];
          const newSuggestions = [];
          const newQueries = [];
          const newIssues = [];

          // Loop through the role_working_history array
          if (Array.isArray(response?.data?.role?.role_working_history)) {
            response?.data?.role?.role_working_history.forEach((historyItem) => {
              // Process info within each history item
              if (Array.isArray(historyItem?.info)) {
                historyItem.info.forEach((info) => {
                  if (IsParsable(info)) {
                    newInfo.push(JSON.parse(info));
                  } else {
                    newInfo.push({
                      role: 'none',
                      info_added: 'No Info added yet',
                      full_name: 'none',
                    });
                  }
                });
              }

              // Process queries within each history item
              if (Array.isArray(historyItem?.queries)) {
                historyItem.queries.forEach((query) => {
                  if (IsParsable(query)) {
                    newQueries.push(JSON.parse(query));
                  } else {
                    newQueries.push({
                      role: 'none',
                      query_added: 'No query added yet',
                      full_name: 'none',
                    });
                  }
                });
              }

              // Process suggestions within each history item
              if (Array.isArray(historyItem?.suggestions)) {
                historyItem.suggestions.forEach((suggestion) => {
                  if (IsParsable(suggestion)) {
                    newSuggestions.push(JSON.parse(suggestion));
                  } else {
                    newSuggestions.push({
                      role: 'none',
                      suggestion_added: 'No suggestion added yet',
                      full_name: 'none',
                    });
                  }
                });
              }

              // Process issues within each history item
              if (Array.isArray(historyItem?.issues)) {
                historyItem.issues.forEach((issue) => {
                  if (IsParsable(issue)) {
                    newIssues.push(JSON.parse(issue));
                  } else {
                    newIssues.push({
                      role: 'none',
                      issue_added: 'No issue added yet',
                      full_name: 'none',
                    });
                  }
                });
              }
            });
          }

          // Set state
          setIssues(newIssues);
          setInfo(newInfo);
          setQueries(newQueries);
          setSuggestions(newSuggestions);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoleDetails();
  }, [roleId]);

  const startRole = (status) => {
    try {
      Post(
        { id: roleId, status: status, user_id: userId },
        API_URLS.startRole,
        (response) => {
          console.log(response);
          setOpenStartRoleConfirmationDialog(false);
          getRoleDetails();
          enqueueSnackbar('Role started successfully', { variant: 'success' });
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddIssue = (issue) => {
    try {
      const payload = {
        id: Number(roleId),
        issue: issue,
        workingUserId: Number(userId),
      };
      Post(
        payload,
        API_URLS.addRoleIssue,
        (resp) => {
          getRoleDetails();
        },
        (resp) => {
          enqueueSnackbar('Can not add Issue', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleEditIssueClick = (index, issue) => {
    const payload = {
      id: parseInt(roleId),
      issueIndex: index,
      updatedIssue: issue,
      workingUserId: Number(userId),
    };

    try {
      Post(
        payload,
        API_URLS.editRoleIssue,
        (resp) => {
          getRoleDetails();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not update issue', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleAddInfo = (info) => {
    try {
      const payload = {
        id: Number(roleId),
        info: info,
        workingUserId: Number(userId),
      };
      Post(
        payload,
        API_URLS.addRoleInfo,
        (resp) => {
          getRoleDetails();
        },
        (resp) => {
          enqueueSnackbar('Can not add Info', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleEditInfoClick = (index, info) => {
    const payload = {
      id: parseInt(roleId),
      infoIndex: index,
      updatedInfo: info,
      workingUserId: Number(userId),
    };

    try {
      Post(
        payload,
        API_URLS.editRoleInfo,
        (resp) => {
          getRoleDetails();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not update info', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleAddSuggestion = (suggestion) => {
    try {
      const payload = {
        id: Number(roleId),
        suggestion: suggestion,
        workingUserId: Number(userId),
      };
      Post(
        payload,
        API_URLS.addRoleSuggestion,
        (resp) => {
          getRoleDetails();
        },
        (resp) => {
          enqueueSnackbar('Can not add suggestion', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleEditSuggestionClick = (index, suggestion) => {
    const payload = {
      id: parseInt(roleId),
      suggestionIndex: index,
      updatedSuggestion: suggestion,
      workingUserId: Number(userId),
    };

    try {
      Post(
        payload,
        API_URLS.editRoleSuggestion,
        (resp) => {
          getRoleDetails();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not update suggestion', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleAddQuery = (query) => {
    try {
      const payload = {
        id: Number(roleId),
        query: query,
        workingUserId: Number(userId),
      };
      Post(
        payload,
        API_URLS.addRoleQuery,
        (resp) => {
          getRoleDetails();
        },
        (resp) => {
          enqueueSnackbar('Can not add query', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleEditQueryClick = (index, query) => {
    const payload = {
      id: parseInt(roleId),
      queryIndex: index,
      updatedQuery: query,
      workingUserId: Number(userId),
    };

    try {
      Post(
        payload,
        API_URLS.editRoleQuery,
        (resp) => {
          getRoleDetails();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not update query', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleMenuClick = (role) => {
    console.log('rolfsds', role);
    if (role?.id === 'markdone') {
      setOpenMarkDoneDialog(true);
    } else {
      setOpenLeaveRoleDialog(true);
    }
  };

  const handleMarkRoleDone = (values, actions) => {
    console.log('fdsffdsf', values);
    try {
      Post(
        values,
        API_URLS.markRoleAsDone,
        (resp) => {
          enqueueSnackbar('Role marked as done', { variant: 'success' });
          getRoleDetails();
          setOpenMarkDoneDialog(false);
          actions.setSubmitting(false); // Stop form submission after handling
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not mark role as done', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleLeaveRole = (payload) => {
    try {
      Post(
        {
          role_id: payload?.roleId,
          user_id: payload?.userId,
          reason: payload?.reason,
        },
        API_URLS.leaveRole,
        (resp) => {
          enqueueSnackbar('Role left', { variant: 'success' });
          getRoleDetails();
          setOpenLeaveRoleDialog(false);
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not leave role', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };


  const getRoleLIs = useCallback(() => {
    try {
      Get(
        {
          roleId: roleId,
          page: liPage,
          limit: liLimit,
        },
        API_URLS.getRoleLIs,
        (resp) => {
          setRoleLis(resp?.data?.data);
          setLiTotalCount(resp?.data?.pagination?.totalLIs);
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Can not get role LI', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [liPage, liLimit, roleId, enqueueSnackbar]);
  useEffect(()=>{
    getRoleLIs();
  },[getRoleLIs])
  return (
    <div style={{ margin: '1rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="space-between">
        <Button
          variant="none"
          sx={{
            color: '#00C1FE',
          }}
          onClick={() => {
            navigate('/resourcing/roles');
          }}
        >
          <ArrowBack fontSize="medium" />
          &nbsp;&nbsp; <Typography variant="h6">Back to Roles</Typography>
        </Button>
      </Box>
      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={4} height="130vh">
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '15px',
              height: '100%',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              sx={{
                backgroundColor: '#E1F7FC',
                color: '#00C1FE',
                padding: '10px',
              }}
            >
              <Typography variant="h6" sx={{ padding: '10px' }}>
                Role Details
              </Typography>
              {/* {userRole === 'resourcer' && ( */}
              <Select
                value={selectedRole}
                onChange={handleOptionChange}
                sx={{
                  width: '140px',

                  '.MuiSelect-select': {
                    color: '#FFFFFF',
                    padding: '10px 14px',
                    height: '10px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00C1FE', // Default border color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00C1FE', // Hover border color
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00C1FE', // Focus border color (blue in this case)
                  },
                  '.MuiSelect-select.MuiSelect-outlined': {
                    backgroundColor: '#00C1FE',
                    border: '2px solid #00C1FE',
                  },
                  '.MuiSelect-icon': {
                    color: '#FFFFFF',
                  },
                }}
              >
                {roleOptions?.map((role) => (
                  <MenuItem key={role.id} value={role.id} onClick={() => handleMenuClick(role)}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <CardContent>
              <div
                style={{
                  // make content scrollable
                  overflowY: 'scroll',
                  height: '100vh',
                }}
                dangerouslySetInnerHTML={{ __html: role?.roles_working_role?.doc_file_template }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} display="flex" flexDirection="column" justifyContent="space-between" height="130vh">
          <Box
            sx={{
              height: '60%',
              display: maximizeIssueBox ? 'none' : 'block',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{
                  backgroundColor: '#E1F7FC',
                  color: '#00C1FE',
                  padding: '8px',
                }}
              >
                <Typography variant="h6" sx={{ padding: '10px' }}>
                  Query String
                </Typography>
                {/* {userRole === 'resourcer' && ( */}
                <Button
                  sx={{
                    background: role?.status === 'Pending' ? '#00C1FE' : 'transparent',
                    color: role?.status === 'Pending' ? '#fff' : '#00C1FE',
                    width: '140px',
                    height: '45px',
                    border: '1px solid #00C1FE',
                    '&:hover': {
                      background: role?.status === 'Pending' ? '#00C1FE' : 'transparent',
                      color: role?.status === 'Pending' ? '#fff' : '#00C1FE',
                    },
                    borderRadius: '5px',
                    padding: '5px',
                    margin: '5px',
                    opacity: role?.status !== 'Pending' ? 0.6 : 1, // Adds a subtle disabled effect
                  }}
                  onClick={() => {
                    setOpenStartRoleConfirmationDialog(true);
                  }}
                  disabled={role?.status !== 'Pending'}
                >
                  {role?.status === 'Pending' ? 'Start Role' : role?.status}
                </Button>
              </Box>
              <QuillEditor htmlValue={''} onTextChange={(html) => setQueryString(html)} />
              <Box display="flex" justifyContent="flex-end" marginRight="1rem">
                <Button
                  sx={{
                    background: '#00C1FE',
                    color: '#fff',
                    width: '100px',
                    height: '45px',
                    border: '1px solid #00C1FE',
                    '&:hover': {
                      background: '#00C1FE',
                      color: '#fff',
                    },
                    borderRadius: '5px',
                    padding: '5px',
                    marginTop: '15px',
                  }}
                  onClick={() => {
                    navigate(`/resourcing/candidates/${roleId}`);
                  }}
                >
                  <Icon icon="gg:search-loading" style={{ fontSize: '20px' }} /> &nbsp; Search
                </Button>
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              height: maximizeIssueBox ? '100%' : '39%',
              marginTop: maximizeIssueBox ? '0px' : '10px',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',

                borderRadius: '15px',
                //give box sahdow
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <IssueBox
                issueData={issues}
                onSubmitIssue={handleAddIssue}
                onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
                onMaximize={() => {
                  setMaximizeIssueBox(true);
                }}
                onMinimize={() => {
                  setMaximizeIssueBox(false);
                }}
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={4} height="130vh">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            sx={{
              backgroundColor: '#E1F7FC',
              color: '#00C1FE',
              padding: '12px',
              height: '7%',
            }}
          >
            <Typography variant="h6" sx={{ padding: '10px' }}>
              Focus Points
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: '10px',
              height: maximizeInfoBox ? '92%' : '30%',
              display: maximizeSuggestionBox ? 'none' : maximizeQueryBox ? 'none' : 'block',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '15px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
                backgroundColor: 'yellow',
              }}
            >
              <InfoBox
                infoData={info}
                onSubmitInfo={handleAddInfo}
                onUpdateInfo={(index, issue) => handleEditInfoClick(index, issue)}
                onMaximize={() => {
                  setMaximizeInfoBox(true); // Toggle between maximize and minimize
                  setMaximizeQueryBox(false);
                  setMaximizeSuggestionBox(false);
                }}
                onMinimize={() => {
                  setMaximizeInfoBox(false); // Toggle between maximize and minimize
                }}
              />
            </Card>
          </Box>
          <Box
            sx={{
              marginTop: '10px',
              display: maximizeSuggestionBox ? 'none' : maximizeInfoBox ? 'none' : 'block',
              height: maximizeQueryBox ? '92%' : '30%',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '15px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
              }}
            >
              <QueryBox
                queryData={queries}
                onSubmitQuery={handleAddQuery}
                onUpdateQuery={(index, issue) => handleEditQueryClick(index, issue)}
                onMaximize={() => {
                  setMaximizeQueryBox(true);
                  setMaximizeInfoBox(false);
                  setMaximizeSuggestionBox(false);
                }}
                onMinimize={() => {
                  setMaximizeQueryBox(false);
                }}
              />
            </Card>
          </Box>

          <Box
            sx={{
              marginTop: '10px',
              height: maximizeSuggestionBox ? '92%' : '30%',
              display: maximizeQueryBox ? 'none' : maximizeInfoBox ? 'none' : 'block',
            }}
          >
            <Card
              sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                borderRadius: '15px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
                transition: 'height 0.3s ease', // Smooth transition
              }}
            >
              <SuggestionBox
                suggestionData={suggestions}
                onSubmitSuggestion={handleAddSuggestion}
                onUpdateSuggestions={(index, issue) => handleEditSuggestionClick(index, issue)}
                onMaximize={() => {
                  setMaximizeSuggestionBox(true);
                  setMaximizeInfoBox(false);
                  setMaximizeQueryBox(false);
                }}
                onMinimize={() => {
                  setMaximizeSuggestionBox(false);
                }}
              />
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <LITable
            roleLIs={roleLis}
            onPageChange={(page) => setLiPage(page)}
            onRowsPerPageChange={(limit) => {
              setLiLimit(limit);
            }}
            totalRows={liTotalCount}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CVsTable
            roleCVs={roleCVs}
            onPageChange={(page) => setCvPage(page)}
            onRowsPerPageChange={(limit) => {
              setCvLimit(limit);
            }}
            totalRows={cvTotalCoount}
          />
        </Grid>
      </Grid>

      {openStartRoleConfirmationDialog && (
        <ActionConfirmationDialog
          open={openStartRoleConfirmationDialog}
          onClose={() => setOpenStartRoleConfirmationDialog(false)}
          title={`Are you sure you want to start ${role?.roles_working_role?.title} role?`}
          actionButtonText="Yes"
          onSubmit={() => startRole('In-Progress')}
        />
      )}
      {openMarkDoneDialog && (
        <MarkRoleAsDoneDialog
          open={openMarkDoneDialog}
          onClose={() => setOpenMarkDoneDialog(false)}
          roleId={roleId}
          onSubmit={(values, actions) => handleMarkRoleDone(values, actions)}
        />
      )}
      {openLeaveRoleDialog && (
        <LeaveRole
          open={openLeaveRoleDialog}
          onClose={() => setOpenLeaveRoleDialog(false)}
          roleId={roleId}
          onSubmit={(payload) => handleLeaveRole(payload)}
        />
      )}
    </div>
  );
}

export default ResourcingRolesProcessing;
