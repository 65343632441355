import React, { useState, useEffect } from 'react';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation schema
import { allServices } from 'src/constants/constants';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

const AddNewService = ({ open, onClose }) => {
  const [selectedService, setSelectedService] = useState(null); // Track selected service
  const [min_idPlaceholder, setMinIdPlaceholder] = useState('');
  const [max_idPlaceholder, setMaxIdPlaceholder] = useState('');
  const [services, setServices] = useState([]);

  const initialValues = {
    service_name: '',
    min_id: '',
    max_id: '',
  };

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    service_name: Yup.string().required('Service Name is required'),
    min_id: Yup.number()
      .required('Min ID is required')
      .test('is-valid-min', 'Min ID is out of range', function (value) {
        if (selectedService) {
          return value >= selectedService.minRange && value <= selectedService.maxRange;
        }
        return true;
      }),
    max_id: Yup.number()
      .required('Max ID is required')
      .test('is-valid-max', 'Max ID is out of range', function (value) {
        if (selectedService) {
          return value >= selectedService.minRange && value <= selectedService.maxRange;
        }
        return true;
      }),
  });

  useEffect(() => {
    if (selectedService) {
      setMinIdPlaceholder(
        `Enter start client number (between ${selectedService.minRange} - ${selectedService.maxRange})`
      );
      setMaxIdPlaceholder(`Enter last client number less than ${selectedService.maxRange}`);
    } else {
      setMinIdPlaceholder('Enter start client number');
      setMaxIdPlaceholder('Enter last client number');
    }
  }, [selectedService]);

  const getServices = () => {
    try {
      Get(
        {},
        API_URLS.getAllServices,
        (resp) => {
          console.log("serviceshainyeh" , resp);
          setServices(resp?.data);
        },
        (error) => {
          enqueueSnackbar('Failed to fetch services', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  const handleAddService = (values, actions) => {
    try {
      Post(
        values,
        API_URLS.createService,
        (resp) => {
          enqueueSnackbar('Service Added Successfully', { variant: 'success' });
          actions.setSubmitting(false)
          getServices();
          onClose();
        },
        (error) => {
          enqueueSnackbar('Failed to add service', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: '25vh',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: '#D1F3FA', width: '100%' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Add New Service</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleAddService(values, actions);
          }}
        >
          {({ values, errors, getFieldProps, setFieldValue, touched, handleSubmit, isSubmitting }) => (
            <Form>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    id="service_name"
                    options={allServices || []}
                    size="large"
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newService) => {
                      setSelectedService(newService);
                      setFieldValue('service_name', newService?.label);
                      setFieldValue('min_id', '');
                      setFieldValue('max_id', '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Service"
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {errors.service_name && touched.service_name && (
                    <Typography color="error">{errors.service_name}</Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="min_id"
                    type="number"
                    placeholder={min_idPlaceholder}
                    size="medium"
                    fullWidth
                    error={touched.min_id && Boolean(errors.min_id)}
                    helperText={touched.min_id && errors.min_id}
                    {...getFieldProps('min_id')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '3px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="max_id"
                    type="number"
                    placeholder={max_idPlaceholder}
                    size="medium"
                    fullWidth
                    error={touched.max_id && Boolean(errors.max_id)}
                    helperText={touched.max_id && errors.max_id}
                    {...getFieldProps('max_id')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginBottom: '3px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                <Button
                  onClick={onClose}
                  size="medium"
                  sx={{
                    backgroundColor: '#A3A3A3',
                    fontSize: '16px',
                    color: 'white',
                    marginLeft: '1rem',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#A3A3A3 !important',
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="medium"
                  loading={isSubmitting}
                  sx={{
                    backgroundColor: '#0ec4fe',
                    fontSize: '16px',
                    color: 'white',
                    marginLeft: '1rem',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                >
                  Add Service
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {services?.length > 0 && (
          <>
            <Typography variant="h6">List of added Services</Typography>
            <Box marginTop="15px" marginLeft="1rem">
              <ul style={{ fontWeight: '600', listStyleType: 'disc', paddingLeft: '20px' , color :"#0ec4fe" }}>
                {services?.map((service, index) => {
                  return (
                    <li key={index} style={{ paddingBottom: '10px' , color : "black" }}>
                      {service?.service_name}
                    </li>
                  );
                })}
              </ul>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default AddNewService;
