import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
} from '@mui/material';
import { Icon } from '@iconify/react';
//import toggle switch .css
import './toggleSwitch.css';

const tableData = [
  {
    title: 'Designer',
    date: '17 oct, 2020',
    link: 'https://www.reed.co.uk/jobs/designer-jobs',
    keywords: 'Designer',
    results: 400,
    scrapper1: {
      results: 200,
      status: 'Completed',
      percentage: '100%',
    },
    scrapper2: {
      results: 100,
      status: 'In-Progress',
      percentage: '50%',
    },
    previousSearches: [
      {
        date: '16 oct, 2020',
        results: 350,
        scrapper1: { results: 200, status: 'Completed', percentage: '100%' },
        scrapper2: { results: 150, status: 'Completed', percentage: '100%' },
      },
      {
        date: '15 oct, 2020',
        results: 300,
        scrapper1: { results: 150, status: 'Completed', percentage: '100%' },
        scrapper2: { results: 150, status: 'In-Progress', percentage: '50%' },
      },
      {
        date: '14 oct, 2020',
        results: 250,
        scrapper1: { results: 100, status: 'Completed', percentage: '100%' },
        scrapper2: { results: 150, status: 'Pending', percentage: '0%' },
      },
    ],
  },
  {
    title: 'Developer',
    date: '17 oct, 2020',
    link: 'https://www.reed.co.uk/jobs/designer-jobs',
    keywords: 'Designer',
    results: 400,
    scrapper1: {
      results: 400,
      status: 'Completed',
      percentage: '100%',
    },
    scrapper2: {
      results: 300,
      status: 'In-Progress',
      percentage: '50%',
    },
  },
  {
    title: 'Designer',
    date: '17 oct, 2020',
    link: 'https://www.reed.co.uk/jobs/designer-jobs',
    keywords: 'Designer',
    results: '200',
    scrapper1: {
      results: 200,
      status: 'Completed',
      percentage: '100%',
    },
    scrapper2: {
      results: 200,
      status: 'Pending',
      percentage: '0%',
    },
  },
];

// ToggleSwitch component for the action cell
function ToggleSwitch({ onToggle }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onToggle(!isChecked);
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={handleToggle} />
      <span className="slider round">
        {/* <span className="icon">{isChecked ? '✔' : '✖'}</span> */}
      </span>
    </label>
  );
}

function ScrapperProgressTable() {
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <TableContainer component={Paper} sx={{ border: '1px solid #D6D4D4' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Job Title</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Link</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Keywords</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Total Results</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Scrapper 1</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Scrapper 2</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row, index) => (
            <React.Fragment key={row?.title}>
              <TableRow
                sx={{ borderBottom: '1px solid #D6D4D4', cursor: 'pointer' }}
                onClick={() => handleRowClick(index)}
              >
                <TableCell style={{ display: 'flex', alignItems: 'center', color: '#0ec4fe' }}>
                  {row?.title}
                  {row?.previousSearches?.length > 0 && (
                    <>
                      {expandedRow === index ? (
                        <Icon icon="iconamoon:arrow-up-2-fill" style={{ marginLeft: '8px', color: '#0ec4fe' }} />
                      ) : (
                        <Icon icon="iconamoon:arrow-down-2-fill" style={{ marginLeft: '8px', color: '#0ec4fe' }} />
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell>{row?.date}</TableCell>
                <TableCell style={{ color: '#87B7FF' }}>
                  <a href={row?.link} target="_blank" rel="noopener noreferrer">
                    {row?.link}
                  </a>
                </TableCell>
                <TableCell>{row?.keywords}</TableCell>
                <TableCell>{row?.results}</TableCell>
                <TableCell>
                  <ScrapperProgress scrapper={row?.scrapper1} />
                </TableCell>
                <TableCell>
                  <ScrapperProgress scrapper={row?.scrapper2} />
                </TableCell>
                <TableCell>
                  <ToggleSwitch
                    onToggle={(isEnabled) => {
                      console.log(`Row for ${row?.title} is now ${isEnabled ? 'enabled' : 'disabled'}`);
                    }}
                  />
                </TableCell>
              </TableRow>

              {/* Render previous searches if row is expanded */}
              {expandedRow === index &&
                row.previousSearches?.map((previous, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: '#f0f0f0',
                      borderBottom: '1px solid #D6D4D4',
                    }}
                  >
                    <TableCell style={{ paddingLeft: '50px' }}>{row?.title} (Previous)</TableCell>
                    <TableCell>{previous.date}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{previous.results}</TableCell>
                    <TableCell>
                      <ScrapperProgress scrapper={previous?.scrapper1} />
                    </TableCell>
                    <TableCell>
                      <ScrapperProgress scrapper={previous?.scrapper2} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ScrapperProgress({ scrapper }) {
  const progressColor =
    scrapper.status === 'Completed'
      ? '#198038'
      : scrapper.status === 'In-Progress'
      ? 'white'
      : '#F1C21B';
  const progressBarColor =
    scrapper.status === 'Completed'
      ? '#198038'
      : scrapper.status === 'In-Progress'
      ? '#104ED1'
      : '#F1C21B';
  const progressValue = parseInt(scrapper.percentage) || 0;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box
        component="span"
        sx={{
          width: 30,
          height: 25,
          borderRadius: '50%',
          backgroundColor: progressColor,
          display: 'flex',
          justifyContent: 'center',
          border: scrapper?.status === 'In-Progress' ? '2px solid blue' : 'none',
          alignItems: 'center',
          marginBottom: 1,
          marginRight: '5px',
        }}
      >
        {scrapper?.status === 'Completed' && (
          <Icon icon="charm:tick" style={{ color: 'white', fontSize: '16px' }} />
        )}
        {scrapper?.status === 'Pending' && (
          <Icon icon="tabler:exclamation-mark" style={{ color: 'black', fontSize: '18px' }} />
        )}
        {scrapper.status === 'In-Progress' && (
          <Icon
            icon="line-md:loading-twotone-loop"
            style={{ color: '#0ec4fe', fontSize: '18px' }}
          />
        )}
      </Box>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <p
            align="center"
            style={{ marginBottom: '5px', fontSize: '12px', fontWeight: 'bold' }}
          >
            {scrapper.results}{' '}
            <span style={{ fontSize: '10px', fontWeight: 'normal' }}>
              ({scrapper.percentage})
            </span>{' '}
          </p>
          <p
            align="center"
            style={{ marginBottom: '5px', fontSize: '10px', fontWeight: 'normal' }}
          >
            <span>{scrapper.status}</span>
          </p>
        </Box>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            width: '100%',
            '& .MuiLinearProgress-bar': {
              backgroundColor: progressBarColor, // Set the progress bar color
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ScrapperProgressTable;
