import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

function CandidatePipelineTable(props) {
  return (
    <div style={{ marginTop: '1rem' }}>
      <Box>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>All Candidates</p>
      </Box>
      <br />
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <Box sx={{ width: '95%', border: '1px solid rgba(0, 0, 0, 0.12)', margin: 'auto', backgroundColor: '#F4F9FD' }}>
          <Stack direction="row">
            <Button
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                outline: 'none',
                flexGrow: 1,
                border: 'none',
                '&:hover': {
                  backgroundColor: '#F4F9FD',
                  outline: 'none',
                  border: 'none',
                },
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6">100</Typography>
              <Typography variant="body1">Total</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                padding: 2,
                flexGrow: 1,
                '&:hover': {
                  backgroundColor: '#F4F9FD',
                  outline: 'none',
                },
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6">25</Typography>
              <Typography variant="body1">Database</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                padding: 2,
                '&:hover': {
                  backgroundColor: '#F4F9FD',
                  outline: 'none',
                },
                flexGrow: 1,
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6">25</Typography>
              <Typography variant="body1">Job Board</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                outline: 'none',
                flexGrow: 1,
                '&:hover': {
                  backgroundColor: '#F4F9FD',
                  outline: 'none',
                },
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6">25</Typography>
              <Typography variant="body1">LinkedIn</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                padding: 2,
                '&:hover': {
                  backgroundColor: '#F4F9FD',
                  outline: 'none',
                },
                flexGrow: 1,
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6">25</Typography>
              <Typography variant="body1">Job Posting</Typography>
            </Button>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}

export default CandidatePipelineTable;
