import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Tooltip,
  TablePagination,
} from '@mui/material';
import { AddCandidate, AssociateCandidate, Microscope } from './images';
import { useNavigate } from 'react-router-dom';

function RolePipelinesTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page

  const data = [
    {
      role: 'Designer',
      candidates: 9,
      stages: {
        allCandidates: 10,
        screening: 8,
        submissions: 8,
        rejected: 2,
        interview: 6,
        offered: 4,
        hired: 4,
      },
    },
    {
      role: 'Developer',
      candidates: 4,
      stages: {
        allCandidates: 3,
        screening: 3,
        submissions: 3,
        rejected: 3,
        interview: 3,
        offered: 3,
        hired: 3,
      },
    },
    {
      role: 'Writer',
      candidates: 4,
      stages: {
        allCandidates: 3,
        screening: 3,
        submissions: 3,
        rejected: 3,
        interview: 3,
        offered: 3,
        hired: 3,
      },
    },
  ];

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();
  return (
    <Box sx={{ padding: 2, borderRadius: 4, backgroundColor: 'white' }}>
      <Typography variant="subtitle2" gutterBottom sx={{ marginLeft: 2 }}>
        Hiring Pipeline
      </Typography>
      <hr style={{ width: '100%', margin: 'auto', color: '#E6EBF5' }} />
      {/* Colored bar directly above table headers */}
      <Box
        display="flex"
        sx={{
          height: '5px',
          width: '82%',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginLeft: 'auto',
          marginRight: 3,
        }}
      >
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#EB65E5', // Designer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#88BCF6', // Developer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#AFBAE0', // Writer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#FF9999', // Rejected
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#EBB794', // Interview
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#67E4BB', // Offered
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#E0CC38', // Hired
            height: '100%',
          }}
        ></span>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <TableCell style={{ textAlign: 'center' }}>Posting Title</TableCell>
              <TableCell style={{ textAlign: 'center' }}>All Candidates</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Screening</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Submissions</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Rejected</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Interview</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Offered</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Hired</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Paginate the rows */}
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: '16%' }}>
                  <Box display="flex" alignItems="center" justifyContent="space-around">
                    <Tooltip title="View JD" placement="top">
                      <img src={Microscope} alt="View JD" width="17px" height="13px" style={{ cursor: 'pointer' }} />
                    </Tooltip>
                    <Tooltip title="Associate candidate" placement="top">
                      <img
                        src={AssociateCandidate}
                        alt="Associate Candidate"
                        width="10px"
                        height="10px"
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                    <Tooltip title="Add candidate" placement="top">
                      <img
                        src={AddCandidate}
                        alt="Add candidate"
                        width="10px"
                        height="10px"
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                    {row.role} ({row.candidates})
                  </Box>
                </TableCell>
                <TableCell sx={{ width: '12%', textAlign: 'center' }}>
                  <p
                    style={{
                      backgroundColor: '#FFE2FE',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #EB65E5',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/allCandidates/1')}
                  >
                    {row.stages.allCandidates}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#DDECFD',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #88BCF6',
                    }}
                  >
                    {row.stages.screening}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#DCE1F3',
                      padding: '10px',
                      width: '55%',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRight: '5px solid #AFBAE0',
                    }}
                  >
                    {row.stages.submissions}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#F6D3D3',
                      padding: '10px',
                      width: '55%',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRight: '5px solid #FF9999',
                    }}
                  >
                    {row.stages.rejected}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#FFE6D6',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #EBB794',
                    }}
                  >
                    {row.stages.interview}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#C7FFED',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #67E4BB',
                    }}
                  >
                    {row.stages.offered}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#FFF5AE',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #E0CC38',
                    }}
                  >
                    {row.stages.hired}
                  </p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination component */}
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
      />
    </Box>
  );
}

export default RolePipelinesTable;
