import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AdvanceSearchComponent, BooleanSearch, HelpWithSearchComponent, save, SaveSearchDialog, ScrapperProgressTable } from './components';
import { Icon } from '@iconify/react';

function ReedsSearchSection(props) {
  const [showBooleanSearch, setShowBooleanSearch] = useState(true);
  const [showHelpSearch, setShowHelpSearch] = useState(false);
  const [openAdvanceSaerch, setOpenAdvanceSaerch] = useState(false);
  const [searchIn, setSearchIn] = useState('cv');
  const [openSaveSearch, setOpenSaveSearch] = useState(false);

  return (
    <div style={{ backgroundColor: 'white', width: '97%', margin: 'auto', padding: '20px', height: 'auto' }}>
      <Box display="flex" marginLeft="10px">
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          CV Search
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="10px" marginBottom="10px" width="100%">
        <Button
          size="large"
          sx={{
            backgroundColor: 'white',
            outline: 'grey',
            flexGrow: 1, // Makes the button expand to fill available space
            borderRadius: '0px',
            borderLeft: '1px solid #D8D8D8',
            borderRight: '1px solid #D8D8D8',
            borderBottom: '1px solid #D8D8D8',
            height: '60px',
            borderTop: showBooleanSearch ? '7px solid #00C1FE' : '7px solid #C1E6F1',
            '&:hover': {
              borderTop: showBooleanSearch ? '7px solid #00C1FE' : '7px solid #C1E6F1',
              backgroundColor: 'white',
              borderLeft: '1px solid #D8D8D8',
              borderRight: '1px solid #D8D8D8',
              borderBottom: '1px solid #D8D8D8',
            },
          }}
          onClick={() => {
            setShowBooleanSearch(true);
            setShowHelpSearch(false);
          }}
        >
          Boolean Search
        </Button>

        <Button
          size="large"
          sx={{
            backgroundColor: 'white',
            outline: 'grey',
            flexGrow: 1, // Makes the button expand to fill available space
            borderRadius: '0px',
            borderLeft: '1px solid #D8D8D8',
            borderRight: '1px solid #D8D8D8',
            borderBottom: '1px solid #D8D8D8',
            height: '60px',
            borderTop: showHelpSearch ? '7px solid #00C1FE' : '7px solid #C1E6F1',
            '&:hover': {
              borderTop: showHelpSearch ? '7px solid #00C1FE' : '7px solid #C1E6F1',
              backgroundColor: 'white',
              borderLeft: '1px solid #D8D8D8',
              borderRight: '1px solid #D8D8D8',
              borderBottom: '1px solid #D8D8D8',
            },
          }}
          onClick={() => {
            setShowHelpSearch(true);
            setShowBooleanSearch(false);
          }}
        >
          Help with Search
        </Button>
      </Box>

      {showBooleanSearch && <BooleanSearch />}
      {showHelpSearch && <HelpWithSearchComponent />}
      {showBooleanSearch && (
        <>
          <Box
            sx={{ marginTop: '10px' }}
            display="flex"
            justifyContent="space-between"
            alignContent="space-between"
            marginRight="1rem"
          >
            {showBooleanSearch && (
              <ToggleButtonGroup
                value={searchIn}
                size="large"
                exclusive
                style={{
                  height: 55,
                  border: '1px solid #ECECEC',
                  borderRadius: '5px',
                  boxSizing: 'border-box',
                  width: '15%',
                }}
                onChange={(event, value) => {
                  setSearchIn(value);
                }}
              >
                <ToggleButton
                  value="cv"
                  style={{
                    borderRight: '1px solid #ccc',
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    background: searchIn === 'cv' ? '#EBEBEB' : 'white',
                    fontWeight: 'normal',
                  }}
                >
                  CV
                </ToggleButton>
                <ToggleButton
                  value="currentJobTitle"
                  fullWidth
                  style={{
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                    background: searchIn === 'currentJobTitle' ? '#EBEBEB' : 'white',
                    fontWeight: 'normal',
                  }}
                >
                  Current Job Title
                </ToggleButton>
              </ToggleButtonGroup>
            )}

            <Button
              size="large"
              sx={{
                backgroundColor: '#b3f1ff',
                color: '#555555',
                borderRadius: 0,
                fontSize: '18px',
                fontWeight: 'normal',
                padding: '5px',
                marginRight: '1rem',
                width: '14%',
                '&:hover': {
                  color: '#555555 !important',
                  borderRadius: 0,
                  padding: '5px',
                  fontSize: '18px',
                  fontWeight: 'normal',
                  backgroundColor: '#b3f1ff !important',
                },
              }}
              onClick={() => {
                if (openAdvanceSaerch) {
                  setOpenAdvanceSaerch(false);
                } else {
                  setOpenAdvanceSaerch(true);
                }
              }}
            >
              Advance Search{' '}
              {openAdvanceSaerch && <Icon icon="ri:arrow-up-s-fill" style={{ marginLeft: '5px', fontSize: '20px' }} />}
            </Button>
          </Box>
        </>
      )}
      {showHelpSearch && (
        <>
          <Box sx={{ marginTop: '10px' }} display="flex" justifyContent="flex-end" marginRight="1rem">
            <Button
              size="large"
              sx={{
                backgroundColor: '#b3f1ff',
                color: '#555555',
                borderRadius: 0,
                fontSize: '18px',
                fontWeight: 'normal',
                padding: '5px',
                marginRight: '1rem',
                width: '14%',
                '&:hover': {
                  color: '#555555 !important',
                  borderRadius: 0,
                  padding: '5px',
                  fontSize: '18px',
                  fontWeight: 'normal',
                  backgroundColor: '#b3f1ff !important',
                },
              }}
              onClick={() => {
                if (openAdvanceSaerch) {
                  setOpenAdvanceSaerch(false);
                } else {
                  setOpenAdvanceSaerch(true);
                }
              }}
            >
              Advance Search{' '}
              {openAdvanceSaerch && <Icon icon="ri:arrow-up-s-fill" style={{ marginLeft: '5px', fontSize: '20px' }} />}
            </Button>
          </Box>
        </>
      )}

      {openAdvanceSaerch && <AdvanceSearchComponent />}
      <Box display="flex" marginTop="1rem">
        <Button
          size="large"
          sx={{
            backgroundColor: '#0ec4fe',
            color: 'white',
            width: '15%',
            borderRadius: '30px',
            '&:hover': {
              color: 'white !important',
              backgroundColor: '#0ec4fe !important',
              borderRadius: '30px',
            },
          }}
          onClick={() => {
            setOpenSaveSearch(true);
          }}
        >
          <img src={save} alt="email" height={13} width={13} />
          &nbsp; &nbsp; Save Search
        </Button>
      </Box>
      <br />
      <ScrapperProgressTable />
      {openSaveSearch && <SaveSearchDialog open={openSaveSearch} onClose={() => setOpenSaveSearch(false)} />}
    </div>
  );
}

export default ReedsSearchSection;
