import { Box, Dialog, DialogContent } from '@mui/material';
import React from 'react';

function CVFormat({ open, onClose, openToWork, candidate }) {
  console.log("rewrerwerwe",candidate);
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            height: '100vh',
            width: '100%',
            maxWidth: '1300px',
            border: openToWork ? '5px solid #57CC99' : '5px solid transparent',
            padding: 0, // Removed padding here to ensure anchor tags and border align
            margin: 0,
            position: 'relative',
            borderRadius: 0,
          },
        }}
      >
        <DialogContent
          sx={{
            position: 'relative',
            overflowY: 'scroll',
            overflowX: 'hidden',
            margin: 0,
            padding: 0,
          }}
        >
          <div>
            <a
              href="#"
              style={{
                position: 'sticky', // Change position to sticky
                top: 0, // Stick to the top
                zIndex: 1, // Ensure it's above other content
                backgroundColor: '#57CC99',
                color: 'white',
                width: '130px',
                padding: '5px 10px',
                textDecoration: 'none',
                fontSize: '14px',
                left: '0px', // Adjust the left property
              }}
            >
              Open to work
            </a>

            {/* <a
              href="#"
              style={{
                position: 'absolute',
                bottom: '0px', // Ensure it's inside the border
                right: '-10px',
                width: '120px',
                backgroundColor: '#57CC99',
                color: 'white',
                padding: '5px 10px',
                textDecoration: 'none',
                fontSize: '14px',
              }}
            >
              Open to work
            </a> */}

            {/* <a
              href="#"
              style={{
                position: 'absolute',
                top: '50%',
                left: '-50px',
                backgroundColor: '#57CC99',
                color: 'white',
                width: '120px',
                padding: '5px 10px',
                textDecoration: 'none',
                fontSize: '14px',
                transform: 'translateY(-50%) rotate(-90deg)',
              }}
            >
              Open to work
            </a>

            <a
              href="#"
              style={{
                position: 'absolute',
                top: '50%',
                right: '-45px',
                backgroundColor: '#57CC99',
                color: 'white',
                width: '120px',
                padding: '5px 10px',
                textDecoration: 'none',
                fontSize: '14px',
                transform: 'translateY(-50%) rotate(90deg)',
              }}
            >
              Open to work
            </a> */}
          </div>
          {/* Your dialog content goes here */}
          <div style={{ padding: '3rem', width: '95%' }}>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <p style={{ fontSize: '26px', fontWeight: 'bold' }}>
                {' '}
                {candidate?.role_cvs_candidate?.full_name || 'Dora Opare'}
              </p>
              <Box display="flex" marginTop="10px">
                <p style={{ fontSize: '16px' }}>
                  {candidate?.role_cvs_candidate?.location || 'GC479, Lane 21 Oniyinase RD, Aoshie - Accra'} |{' '}
                  <span style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}>
                    {candidate?.role_cvs_candidate?.personal_email || 'doralaurine@gmail.com'}
                  </span>
                  |{' '}
                  <span style={{ marginLeft: '5px' }}>
                    {candidate?.role_cvs_candidate?.personal_phone || '08012345678'}
                  </span>
                </p>
              </Box>
              {/* <Box display="flex">
                <p style={{ fontSize: '16px', color: '#3F8CFF' }}>
                  {candidate?.link || 'https://www.w3schools.com/colors/colors_picker.asp'}{' '}
                  <span style={{ color: 'black' }}>|</span>{' '}
                  <span style={{ marginLeft: '5px' }}>{candidate?.website || 'https://www.w3schools.com'}</span>
                </p>
              </Box> */}
            </Box>
            <Box display="flex" marginLeft="20px">
              <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Education</p>
            </Box>
            <hr
              style={{
                color: '#B0B0B0',
                height: '3px',
                border: 'none',
                backgroundColor: '#CAC5C5',
                marginLeft: '20px',
              }}
            />
            <Box display="flex" marginLeft="20px" justifyContent="space-between">
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                {candidate?.institute || 'Islamia University of Science and Technology'}
              </p>
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                {candidate?.startDate || 'Oct. 2014 '} - {candidate?.endDate || 'Oct. 2016'}
              </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="15px">
              <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Technical Skills</p>
            </Box>
            <hr
              style={{
                color: '#B0B0B0',
                height: '3px',
                border: 'none',
                backgroundColor: '#CAC5C5',
                marginLeft: '20px',
              }}
            />
            <Box display="flex" marginLeft="20px" flexDirection="column">
              <p style={{ fontSize: '16px', marginTop: '18px' }}>
                <span style={{ fontWeight: 'bold' }}>
                  {candidate?.langauges || 'Pogramming Languages / Design Skills:'}
                </span>
                <span style={{ marginLeft: '5px' }}>
                  HTML , CSS , Java , PHP , UI.UX Design , Agile Methodologies, Responsive design , Interactive Design ,
                  Responsive design , Interactive Design
                </span>
              </p>
              <p style={{ fontSize: '16px', marginTop: '18px' }}>
                <span style={{ fontWeight: 'bold' }}>{candidate?.tools || 'Tools:'}</span>
                <span style={{ marginLeft: '5px' }}>
                  Figma , Adobe XD , Adobe Photoshop , Adobe Illustrator , Trello , Zoom , Slack , Microsoft Office
                  Suite, Figma , Adobe XD , Adobe Photoshop , Adobe Illustrator , Trello , Zoom , Slack , Microsoft
                  Office
                </span>
              </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="15px">
              <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Experience</p>
            </Box>
            <hr
              style={{
                color: '#B0B0B0',
                height: '3px',
                border: 'none',
                backgroundColor: '#CAC5C5',
                marginLeft: '20px',
              }}
            />
            {candidate?.role_cvs_candidate?.work_history?.length > 0 && (
              <>
                {candidate?.role_cvs_candidate?.work_history?.map((work, index) => (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" marginLeft="20px" justifyContent="space-between">
                      <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                        {work?.jobTitle}, {work?.company}
                      </p>
                      <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>{candidate?.duration}</p>
                    </Box>
                    {/* <Box display="flex" marginLeft="20px" justifyContent="space-between" flexDirection="column">
                      <p style={{ fontSize: '16px', marginTop: '5px' }}>
                        <span style={{ fontWeight: 'bold' }}> {candidate?.firstExperienceLocation || 'Plendify'} </span>
                        <span>, Acra - Ghana.</span>
                      </p>
               
                      <ul style={{ listStyleType: 'none', margin: 0 }}>
                        <li style={{ position: 'relative', paddingLeft: '20px' }}>
                          <span
                            style={{
                              position: 'absolute',
                              left: '0',
                              top: '5px',
                              width: '10px',
                              height: '10px',
                              backgroundColor: 'black',
                              borderRadius: '50%',
                              display: 'inline-block',
                            }}
                          ></span>
                          {candidate?.firstExperienceDescription ||
                            'Collaborate closely with other product leads and engineers on the Plendify B2B market , a SAAS B2B ecommerce platform for buting and selling in bulk and retail under Plendify.'}
                        </li>
                        <li style={{ position: 'relative', paddingLeft: '20px' }}>
                          <span
                            style={{
                              position: 'absolute',
                              left: '0',
                              top: '5px',
                              width: '10px',
                              height: '10px',
                              backgroundColor: 'black',
                              borderRadius: '50%',
                              display: 'inline-block',
                            }}
                          ></span>
                          {candidate?.firstExperienceDescription ||
                            'Anaylze customer and business problems to identify pain points.'}
                        </li>
                        <li style={{ position: 'relative', paddingLeft: '20px' }}>
                          <span
                            style={{
                              position: 'absolute',
                              left: '0',
                              top: '5px',
                              width: '10px',
                              height: '10px',
                              backgroundColor: 'black',
                              borderRadius: '50%',
                              display: 'inline-block',
                            }}
                          ></span>
                          {candidate?.firstExperienceDescription ||
                            'Establish  understanding of business and user needs through design research and usability tests.'}
                        </li>
                
                      </ul>
                    </Box> */}
                  </Box>
                ))}
              </>
            )}
            {/* <Box display="flex" flexDirection="column">
              <Box display="flex" marginLeft="20px" justifyContent="space-between">
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                  {candidate?.firstExperience || 'Snr. UI/UX Designer , Agile Lead'}
                </p>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                  {candidate?.startDate || 'Oct. 2014 '} - {candidate?.endDate || 'Present'}
                </p>
              </Box>
              <Box display="flex" marginLeft="20px" justifyContent="space-between" flexDirection="column">
                <p style={{ fontSize: '16px', marginTop: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}> {candidate?.firstExperienceLocation || 'Plendify'} </span>
                  <span>, Acra - Ghana.</span>
                </p>
   
                <ul style={{ listStyleType: 'none', margin: 0 }}>
                  <li style={{ position: 'relative', paddingLeft: '20px' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '5px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {candidate?.firstExperienceDescription ||
                      'Collaborate closely with other product leads and engineers on the Plendify B2B market , a SAAS B2B ecommerce platform for buting and selling in bulk and retail under Plendify.'}
                  </li>
                  <li style={{ position: 'relative', paddingLeft: '20px' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '5px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {candidate?.firstExperienceDescription ||
                      'Anaylze customer and business problems to identify pain points.'}
                  </li>
                  <li style={{ position: 'relative', paddingLeft: '20px' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '5px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {candidate?.firstExperienceDescription ||
                      'Establish  understanding of business and user needs through design research and usability tests.'}
                  </li>
       
                </ul>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" marginLeft="20px" justifyContent="space-between">
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                  {candidate?.firstExperience || 'Lead UI/UX Designer, Agile Lead'}
                </p>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>
                  {candidate?.startDate || 'Oct. 2014 '} - {candidate?.endDate || 'Oct. 2016'}
                </p>
              </Box>
              <Box display="flex" marginLeft="20px" justifyContent="space-between" flexDirection="column">
                <p style={{ fontSize: '16px', marginTop: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}> {candidate?.firstExperienceLocation || 'Plendify'} </span>
                  <span>, Acra - Ghana.</span>
                </p>
                <ul style={{ listStyleType: 'none', margin: 0 }}>
                  <li style={{ position: 'relative', paddingLeft: '20px' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '5px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {candidate?.firstExperienceDescription ||
                      'Collaborate closely with other product leads and engineers on the Plendify B2B market , a SAAS B2B ecommerce platform for buting and selling in bulk and retail under Plendify.'}
                  </li>
                  <li style={{ position: 'relative', paddingLeft: '20px' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '5px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {candidate?.firstExperienceDescription ||
                      'Anaylze customer and business problems to identify pain points.'}
                  </li>
                  <li style={{ position: 'relative', paddingLeft: '20px' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '5px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        display: 'inline-block',
                      }}
                    ></span>
                    {candidate?.firstExperienceDescription ||
                      'Establish  understanding of business and user needs through design research and usability tests.'}
                  </li>
                
                </ul>
              </Box>
            </Box> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CVFormat;
