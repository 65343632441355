import React from 'react';
import { RolePipelinesTable } from './components';

function RecruiterDashboard(props) {
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <p style={{ fontSize: '18px', color: '#A0A9B4' }}>Welcome back, John!</p>
      <p style={{ fontSize: '36px', fontWeight: 'bold', marginTop: '10px' }}>Dashboard</p>
      <br />
      <RolePipelinesTable />
    </div>
  );
}

export default RecruiterDashboard;
