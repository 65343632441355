import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  active_within,
  current_salary,
  distance,
  hideProfileViesOptions,
  salary_ranges,
  travel_time,
} from './constants';
import React, { useEffect, useState } from 'react';

function SearchComponent({onSearch}) {
  const [selectedSearch, setSelectedSearch] = useState('search');
  const [searchString, setSearchString] = useState();
  const [locationType, setLocationType] = useState('current');
  const [radiusMiles, setRadiusMiles] = useState();
  const [currentSalary, setCurrentSalary] = useState();
  const [salaryRangge, setSalaryRangge] = useState();
  const [willingToRelocate, setWillingToRelocate] = useState();
  const [rangeNotSpecified, setRangeNotSpecified] = useState();
  const [searchIn, setSearchIn] = useState('jobTitleOnly');
  const [activeWithin, setActiveWithin] = useState();
  const [hideProfileSince, setHideProfileSince] = useState();

  const options = ['Both', 'CV Only'];
  const [search, setSearch] = useState(false);
  const [cvOnly, setCvOnly] = useState(options?.[0] || '');
  const groupedOptions = [...travel_time, ...distance];
  useEffect(() => {
    if (!cvOnly) {
      setCvOnly(options[0]);
    }
  }, [options, cvOnly]);
  return (
    <div>
      <Box display="flex" flexDirection="column" sx={{ backgroundColor: 'white', height: 'auto' }}>
        <Box sx={{ width: '100%' }}>
          <ToggleButtonGroup
            value={selectedSearch}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                setSelectedSearch(value);
              }
            }}
            aria-label="company sections"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              padding: 10,
              marginLeft: '20px',
            }}
          >
            <ToggleButton
              value="search"
              aria-label="search"
              style={{
                border: 'none',
                color: selectedSearch === 'search' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selectedSearch === 'search' ? '3px solid #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                backgroundColor: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Search
            </ToggleButton>
            <ToggleButton
              value="targetedSearch"
              aria-label="targetedSearch"
              style={{
                border: 'none',
                color: selectedSearch === 'targetedSearch' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selectedSearch === 'targetedSearch' ? '3px solid  #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                width: '180px',
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Targeted Search
            </ToggleButton>
          </ToggleButtonGroup>
          <hr style={{ width: '95%', margin: 'auto', marginTop: '-10px', color: '#CAC5C5' }} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="20px" marginTop="20px" marginRight="20px">
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <label htmlFor="searchString" style={{ fontWeight: 'normal' }}>
                  Search anything in CV or Profile
                </label>
                {selectedSearch === 'targetedSearch' && (
                  <Box display="flex" justifyContent="space-around" alignItems="center">
                    <p style={{ fontSize: '18px', paddingRight: '5px' }}>CV / profile</p>
                    <Select
                      value={cvOnly}
                      onChange={(event) => setCvOnly(event.target.value)}
                      sx={{
                        width: '140px',
                        '.MuiSelect-select': {
                          color: '#000000', // Text color
                          padding: '8px 10px',
                          height: '10px',
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#B0B0B0', // Grey border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#B0B0B0', // Hover border color
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#B0B0B0', // Focus border color
                        },
                        '.MuiSelect-select.MuiSelect-outlined': {
                          backgroundColor: '#FFFFFF', // White background
                          border: '2px solid #B0B0B0', // Grey border
                        },
                        '.MuiSelect-icon': {
                          color: '#000000', // Icon color
                        },
                      }}
                    >
                      {options?.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            </Box>
            <TextField
              type="text"
              id="searchString"
              name="searchString"
              placeholder={`Enter keywords or Boolean search`}
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              fullWidth
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                marginTop: '5px',
                marginRight: '20px',
                border: '1px solid #CAC5C5',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          </Grid>
          {selectedSearch === 'targetedSearch' && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="20px" marginTop="20px" marginRight="20px">
                <Box display="flex" flexDirection="column">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <label htmlFor="searchString" style={{ fontWeight: 'normal' }}>
                      Search in Job Title
                    </label>
                    {selectedSearch === 'targetedSearch' && (
                      <Box display="flex" justifyContent="space-around" alignItems="center">
                        <p style={{ fontSize: '18px', paddingRight: '5px' }}>Current / Desired</p>
                        <Select
                          value={cvOnly}
                          onChange={(event) => setCvOnly(event.target.value)}
                          sx={{
                            width: '140px',
                            '.MuiSelect-select': {
                              color: '#000000', // Text color
                              padding: '8px 10px',
                              height: '10px',
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#B0B0B0', // Grey border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#B0B0B0', // Hover border color
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#B0B0B0', // Focus border color
                            },
                            '.MuiSelect-select.MuiSelect-outlined': {
                              backgroundColor: '#FFFFFF', // White background
                              border: '2px solid #B0B0B0', // Grey border
                            },
                            '.MuiSelect-icon': {
                              color: '#000000', // Icon color
                            },
                          }}
                        >
                          {options?.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}
                  </Box>
                </Box>
                <TextField
                  type="text"
                  id="searchString"
                  name="searchString"
                  placeholder={`e.g "Marketing Executive"`}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    marginTop: '5px',
                    marginRight: '20px',
                    border: '1px solid #CAC5C5',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>
            </>
          )}
          {selectedSearch === 'targetedSearch' && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="20px" marginTop="20px" marginRight="20px">
                <Box display="flex" flexDirection="column">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <label htmlFor="searchString" style={{ fontWeight: 'normal' }}>
                      Search in Skills
                    </label>
                  </Box>
                </Box>
                <TextField
                  type="text"
                  id="searchString"
                  name="searchString"
                  placeholder={`e.g "Communication" or "Research""`}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    marginTop: '5px',
                    marginRight: '20px',
                    border: '1px solid #CAC5C5',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="20px" marginTop="2px" marginRight="20px">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" flexDirection="column">
                  <ToggleButtonGroup
                    value={locationType}
                    size="large"
                    exclusive
                    style={{
                      height: 55,
                      border: '1px solid #CAC5C5',
                      borderRadius: '5px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event, value) => {
                      setLocationType(value);
                    }}
                  >
                    <ToggleButton
                      value="current"
                      fullWidth
                      style={{
                        borderRight: '1px solid #ccc',
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        background: locationType === 'current' ? '#EBEBEB' : 'white',
                        fontWeight: 'normal',
                      }}
                    >
                      Current location
                    </ToggleButton>
                    {selectedSearch === 'search' && (
                      <ToggleButton
                        value="desired"
                        fullWidth
                        style={{
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          background: locationType === 'desired' ? '#EBEBEB' : 'white',
                          fontWeight: 'normal',
                        }}
                      >
                        Desired location
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>

                  <TextField
                    type="text"
                    id="searchString"
                    name="searchString"
                    placeholder={`Location`}
                    value={searchString}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      borderLeft: '1px solid #CAC5C5',
                      borderRight: '1px solid #CAC5C5',
                      borderBottom: '1px solid #CAC5C5',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#CAC5C5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#CAC5C5',
                          borderWidth: '0',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #CAC5C5',
                          borderWidth: '0',
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Box display="flex" flexDirection="column" marginLeft="5px">
                  <label
                    htmlFor="radius_miles"
                    style={{ fontWeight: 'bold', height: 55, marginLeft: '5px', paddingTop: '20px' }}
                  >
                    Select Radius Miles
                  </label>
                  <Autocomplete
                    id="radius_miles"
                    fullWidth
                    value={radiusMiles} // Change here
                    options={groupedOptions}
                    groupBy={(option) => option.group} // Group by 'Travel Time' or 'Distance'
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setRadiusMiles(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select radius or travel time"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          marginTop: 'px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#CAC5C5',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#CAC5C5',
                              borderWidth: '0',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #CAC5C5',
                              borderWidth: '0',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box display="flex" flexDirection="column" marginLeft="5px">
                  <label
                    htmlFor="current_salary"
                    style={{ fontWeight: 'bold', height: 55, marginLeft: '5px', paddingTop: '20px' }}
                  >
                    Current Salary
                  </label>
                  <Autocomplete
                    id="current_salary"
                    fullWidth
                    value={currentSalary} // Change here
                    options={current_salary}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setCurrentSalary(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Any"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          marginTop: 'px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#CAC5C5',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#CAC5C5',
                              borderWidth: '0',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #CAC5C5',
                              borderWidth: '0',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box display="flex" flexDirection="column" marginLeft="5px">
                  <label
                    htmlFor="salary_range"
                    style={{ fontWeight: 'bold', height: 55, marginLeft: '5px', paddingTop: '20px' }}
                  >
                    Ranges
                  </label>
                  <Autocomplete
                    id="salary_range"
                    multiple // Allows multiple selections
                    fullWidth
                    value={currentSalary} // The array of selected options
                    options={salary_ranges} // Pass the salary ranges array here
                    getOptionLabel={(option) => option} // Defines how to display options
                    onChange={(event, newValue) => {
                      setSalaryRangge(newValue); // Update the selected values
                    }}
                    disableCloseOnSelect // Keep the dropdown open after selecting an option
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected} // Shows checkbox as selected if option is selected
                          sx={{
                            color: selected ? '#00C1FE' : undefined, // Change color when selected
                            '&.Mui-checked': {
                              color: '#00C1FE', // Ensure the checkmark is blue when checked
                            },
                          }}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select salary ranges"
                        variant="outlined"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          marginTop: 'px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#CAC5C5',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#CAC5C5',
                              borderWidth: '0',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #CAC5C5',
                              borderWidth: '0',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '2px' }}>
            <Box display="flex" justifyContent="space-between" marginLeft="20px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={willingToRelocate}
                    onChange={(event) => {
                      setWillingToRelocate(event.target.checked);
                    }}
                    sx={{
                      color: '#EBEBEB', // Default color
                      '&.Mui-checked': {
                        color: '#00C1FE', // Change to blue when checked
                      },
                    }}
                  />
                }
                label=" +Willing to relocate"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rangeNotSpecified}
                    onChange={(event) => {
                      setRangeNotSpecified(event.target.checked);
                    }}
                    sx={{
                      color: '#EBEBEB', // Default color
                      '&.Mui-checked': {
                        color: '#00C1FE', // Change to blue when checked
                      },
                    }}
                  />
                }
                label=" +Not Specified"
                labelPlacement="end"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="20px" marginRight="20px">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" flexDirection="column" marginLeft="5px">
                  <label htmlFor="active_within" style={{ fontWeight: 'bold', marginBottom: '7px' }}>
                    Search In
                  </label>
                  <ToggleButtonGroup
                    value={searchIn}
                    size="large"
                    exclusive
                    style={{
                      height: 55,
                      border: '1px solid #CAC5C5',
                      borderRadius: '5px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event, value) => {
                      setSearchIn(value);
                    }}
                  >
                    <ToggleButton
                      value="jobTitleOnly"
                      fullWidth
                      style={{
                        borderRight: '1px solid #ccc',
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        background: searchIn === 'jobTitleOnly' ? '#EBEBEB' : 'white',
                        fontWeight: 'normal',
                      }}
                    >
                      Job title only
                    </ToggleButton>
                    <ToggleButton
                      value="profileAndCV"
                      fullWidth
                      style={{
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                        background: searchIn === 'profileAndCV' ? '#EBEBEB' : 'white',
                        fontWeight: 'normal',
                      }}
                    >
                      Profile & CV
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box display="flex" flexDirection="column" marginLeft="5px">
                  <label htmlFor="active_within" style={{ fontWeight: 'bold', marginBottom: '7px' }}>
                    Active Within
                  </label>
                  <Autocomplete
                    id="active_within"
                    fullWidth
                    value={activeWithin} // Change here
                    options={active_within}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setActiveWithin(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="1 day"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          marginTop: 'px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#CAC5C5',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#CAC5C5',
                              borderWidth: '0',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #CAC5C5',
                              borderWidth: '0',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box display="flex" flexDirection="column" marginLeft="5px">
                  <label htmlFor="hide_profile_views" style={{ fontWeight: 'bold', marginBottom: '7px' }}>
                    Hide profiles viewed since
                  </label>
                  <Autocomplete
                    id="hide_profile_views"
                    fullWidth
                    value={hideProfileSince} // Change here
                    options={hideProfileViesOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setHideProfileSince(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Today"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          marginBottom: '3px',
                          marginTop: 'px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#CAC5C5',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#CAC5C5',
                              borderWidth: '0',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #CAC5C5',
                              borderWidth: '0',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} marginBottom="2rem">
            <Box display="flex" marginLeft="20px" justifyContent="space-between">
              <Button
                sx={{
                  background: 'white',
                  color: '#00C1FE',
                  width: '200px',
                  height: '45px',
                  fontSize: '16px',
                  '&:hover': {
                    background: 'white',
                    color: '#00C1FE',
                  },
                  borderRadius: '5px',
                  padding: '5px',
                  marginTop: '15px',
                }}
              >
                Advance Search
              </Button>
              <Box display="flex" marginRight="20px">
                <Button
                  sx={{
                    background: 'white',
                    color: '#00C1FE',
                    width: '200px',
                    fontSize: '16px',
                    height: '45px',
                    '&:hover': {
                      background: 'white',
                      color: '#00C1FE',
                    },
                    borderRadius: '5px',
                    padding: '5px',
                    marginTop: '15px',
                  }}
                >
                  Clear Search
                </Button>
                <Button
                  sx={{
                    background: '#00C1FE',
                    color: '#fff',
                    width: '100px',
                    height: '45px',
                    border: '1px solid #00C1FE',
                    '&:hover': {
                      background: '#00C1FE',
                      color: '#fff',
                    },
                    borderRadius: '5px',
                    padding: '5px',
                    marginTop: '15px',
                  }}
                  onClick={() => {
                    setSearch(true);
                    onSearch();
                  }}
                >
                  Search
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
      </Box>
    </div>
  );
}

export default SearchComponent;
