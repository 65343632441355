import React from 'react';
import { CandidatePipelineTable } from './components';

function CandidatesSection(props) {
    return (
      <div>
        <CandidatePipelineTable />
      </div>
    );
}

export default CandidatesSection;