import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { searches } from './constants';

function SaveSearchDialog({ open, onClose }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [jobTitle, setJobTitle] = useState();
  const [location, setLocation] = useState();
  const [selectedSearch, setSelectedSearch] = useState();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="flex-end">
            <Icon icon="maki:cross" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={onClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedOption !== 'new' && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Choose your option</p>
            </Box>
          )}
          {selectedOption !== 'new' && (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" marginTop="2rem">
                <Button
                  size="large"
                  sx={{
                    backgroundColor: '#0ec4fe',
                    color: 'white',
                    width: '30%',
                    borderRadius: '25px',
                    padding: '10px 30px',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                  onClick={() => setSelectedOption('new')}
                >
                  New
                </Button>
                <Button
                  size="large"
                  sx={{
                    backgroundColor: '#0ec4fe',
                    color: 'white',
                    width: '30%',
                    borderRadius: '25px',
                    padding: '10px 30px',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                  onClick={() => setSelectedOption('existing')}
                >
                  Existing
                </Button>
              </Box>
            </>
          )}

          {selectedOption === 'new' && (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
                <TextField
                  id="job_title"
                  name="job_title"
                  type="text"
                  placeholder="Enter title"
                  size="large"
                  value={jobTitle}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setJobTitle(e.target.value)}
                  sx={{
                    backgroundColor: 'white',
                    outline: 'none',
                    border: '1px solid #D9D9D9',
                    marginBottom: '3px',
                    borderRadius: '15px',
                    marginLeft: '5px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#cccccc',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#cccccc',
                      },
                    },
                  }}
                />
                <TextField
                  id="location"
                  name="location"
                  type="text"
                  placeholder="Enter location"
                  size="large"
                  value={location}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setLocation(e.target.value)}
                  sx={{
                    backgroundColor: 'white',
                    outline: 'none',
                    border: '1px solid #D9D9D9',
                    marginBottom: '3px',
                    marginTop: '10px',
                    borderRadius: '15px',
                    marginLeft: '5px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#cccccc',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#cccccc',
                      },
                    },
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" marginTop="1rem">
                <Button
                  size="large"
                  sx={{
                    backgroundColor: 'white',
                    color: '#0ec4fe',
                    width: '25%',
                    borderRadius: 0,
                    border: '2px solid #0ec4fe',
                    padding: '10px 30px',
                    '&:hover': {
                      color: '#0ec4fe !important',
                      backgroundColor: 'white !important',
                    },
                  }}
                  onClick={() => setSelectedOption('')}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  sx={{
                    backgroundColor: '#0ec4fe',
                    color: 'white',
                    width: '25%',
                    borderRadius: 0,
                    padding: '10px 30px',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
          {selectedOption === 'existing' && (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
              <FormControl>
                <label
                  htmlFor="select-single-native"
                  style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
                >
                  Select your search
                </label>
                <Select
                  native
                  value={selectedSearch}
                  onChange={(e) => setSelectedSearch(e.target.value)}
                  inputProps={{
                    id: 'select-single-native',
                  }}
                  // Style for height of the dropdown
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Set specific height for the dropdown
                      },
                    },
                  }}
                  sx={{
                    width: '500px',
                    '.MuiSelect-select': {
                      color: '#000000', // Text color
                      padding: '8px 10px',
                      height: '20px',

                      borderRadius: 0,
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Grey border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Hover border color
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D9D9D9', // Focus border color
                    },
                    '.MuiSelect-select.MuiSelect-outlined': {
                      backgroundColor: '#FFFFFF', // White background
                      border: '2px solid #D9D9D9', // Grey border
                    },
                    '.MuiSelect-icon': {
                      color: '#000000', // Icon color
                    },
                  }}
                >
                  <option aria-label="None" value="" /> {/* Optional: Placeholder option */}
                  {searches?.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SaveSearchDialog;
