import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

const ResumeForm = ({ initialValues, onSubmit }) => {
  const emptyValues = {
    address: {
      city: '',
      country: '',
      postalCode: '',
      street: '',
    },
    currentJobTitle: '',
    domainAndField: {
      domain: '',
      field: '',
    },
    education: [
      {
        degree: '',
        graduationYear: '',
        institution: '',
      },
    ],
    email: '',
    experience: [
      {
        company: '',
        duration: '',
        jobTitle: '',
      },
    ],
    fullName: '',
    phoneNumber: '',
  };

  const formValues = initialValues || emptyValues;
  console.log('initialValues', initialValues, formValues, emptyValues);

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {({ values, getFieldProps }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Full Name
              </label>
              <TextField {...getFieldProps('fullName')} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Phone Number
              </label>
              <TextField {...getFieldProps('phoneNumber')} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Email
              </label>
              <TextField {...getFieldProps('email')} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Current Job Title
              </label>
              <TextField {...getFieldProps('currentJobTitle')} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Domain
              </label>
              <TextField {...getFieldProps('domainAndField.domain')} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Field
              </label>
              <TextField {...getFieldProps('domainAndField.field')} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                City
              </label>
              <TextField {...getFieldProps('address.city')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Country
              </label>
              <TextField {...getFieldProps('address.country')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Postal Code
              </label>
              <TextField {...getFieldProps('address.postalCode')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Street
              </label>
              <TextField {...getFieldProps('address.street')} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Education</Typography>
              <br />
              <FieldArray name="education">
                {({ push, remove }) => (
                  <div>
                    {values?.education?.map((_, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Degree
                            </label>
                            <TextField {...getFieldProps(`education[${index}].degree`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Graduation year
                            </label>
                            <TextField {...getFieldProps(`education[${index}].graduationYear`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Institution
                            </label>
                            <TextField {...getFieldProps(`education[${index}].institution`)} fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <Button type="button" onClick={() => remove(index)}>
                              <Delete style={{ color: '#00C1FE' }} />
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() => push({ degree: '', graduationYear: '', institution: '' })}
                      sx={{ color: '#00C1FE' }}
                    >
                      Add Education
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Experience</Typography>
              <br />
              <FieldArray name="experience">
                {({ push, remove }) => (
                  <div>
                    {values?.experience?.map((_, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              company
                            </label>
                            <TextField {...getFieldProps(`experience[${index}].company`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Duration
                            </label>
                            <TextField {...getFieldProps(`experience[${index}].duration`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Job Title
                            </label>
                            <TextField {...getFieldProps(`experience[${index}].jobTitle`)} fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <Button type="button" onClick={() => remove(index)}>
                              <Delete style={{ color: '#00C1FE' }} />
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() => push({ company: '', duration: '', jobTitle: '' })}
                      sx={{ color: '#00C1FE' }}
                    >
                      Add Experience
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                sx={{
                  mt: 2,
                  padding: '15px',
                  display: 'flex',
                  marginBottom: '10px',
                  justifyContent: 'center',
                  backgroundColor: '#2BCBFE',
                  color: 'white',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                }}
              >
                Add CV to this role
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ResumeForm;
