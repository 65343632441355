import { Icon } from '@iconify/react';
import {  Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';

function BooleanSearch(props) {
  const [jobTitle, setJobTitle] = useState();
  const [miles, setMiles] = useState();
  const [postalCode, setPostalCode] = useState();

  return (
    <div>
      <div style={{ width: '97%', marginTop: '10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="job_title"
              name="job_title"
              type="text"
              placeholder="Job Title or Skill"
              size="large"
              value={jobTitle}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setJobTitle(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="miles"
              name="miles"
              type="text"
              value={miles}
              placeholder="Within 0 miles of"
              size="large"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMiles(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              id="postalCode"
              name="postalCode"
              type="text"
              value={postalCode}
              placeholder="Town or postcode"
              size="large"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setPostalCode(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Button
              size="large"
              fullWidth
              sx={{
                backgroundColor: '#0ec4fe',
                color: 'white',
                height: '97%',
                marginLeft: '5px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
            >
              <Icon icon="teenyicons:search-outline" style={{ fontSize: '24px' }} />
            </Button>
          </Grid>
        </Grid>
    
      </div>
    </div>
  );
}

export default BooleanSearch;
