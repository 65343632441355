import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import {
  EmailSendingReportingPage,
  PersonsTableReporting,
  ScrapperReportingPage,
  StatsCard,
  TeamReportingPage,
} from './components';
import HighchartsPieChart from '../campaigns/components/HighchartsPieChart';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import _, { set } from 'lodash'; // Import lodash for debounce
import { ClickablePieChart } from '../campaigns/components';
import MultiDatePicker from 'src/components/MultiDatePicker';

function AdminDashboard(props) {
  const { enqueueSnackbar } = useSnackbar();

  //new arrays
  const status = [
    {
      id: 'verified',
      label: 'Verified',
    },
    {
      id: 'bounced',
      label: 'Bounced',
    },
    {
      id: 'unchecked',
      label: 'Un-checked',
    },
  ];
  // new states
  const [showTeamReportingPage, setShowTeamReportingPage] = useState(false);
  const [formattedDates, setFormattedDates] = useState([null, null]);
  const [overalAllStats, setOveralAllStats] = useState();
  const [showLeadsReportingPage, setShowLeadsReportingPage] = useState(true);
  const [showScrapperReprotingPage, setShowScrapperReportingPage] = useState(false);
  const [showEmailSendingReportingPage, setShowEmailSendingReportingPage] = useState(false);

  // -----------------------------------------------------------------
  const [userBasedStatsEmailFinder, setUserBasedStatsEmailFinder] = useState();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [systemReport, setSystemReport] = useState();
  const [countires, setCountires] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  // const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [dynamicStats, setDynamicStats] = useState();

  const [selectedFilter, setSelectedFilter] = useState('');

  const chartData = [
    {
      name: 'Remaining Leads',
      value: userBasedStatsEmailFinder ? userBasedStatsEmailFinder?.remainingLeads : 0,
      color: '#FFB27D',
    },
    {
      name: 'Verified Leads',
      value: userBasedStatsEmailFinder ? userBasedStatsEmailFinder?.verifiedLeads : 0,
      color: '#9EC97F',
    },
    {
      name: 'Bounce',
      value: userBasedStatsEmailFinder ? userBasedStatsEmailFinder?.bouncebackPersons : 0,
      color: '#FF6060',
    },
  ];

  const withEmailReporting = [
    {
      name: 'Checked Emails',
      value: overalAllStats ? overalAllStats?.checkedEmailsCount : 0,
      color: '#EB65E5',
    },
    {
      name: 'Unchecked Emails',
      value: overalAllStats ? overalAllStats?.unCheckedEmailsCount : 0,
      color: '#F58538',
    },
  ];
  const checkedEmailReporting = [
    {
      name: 'Bounced',
      value: overalAllStats ? overalAllStats?.bouncedEmailsCount : 0,
      color: '#F14040',
    },
    {
      name: 'Verified',
      value: overalAllStats ? overalAllStats?.verifiedEmailsCount : 0,
      color: '#3FC28A',
    },
  ];

  const awsEmailReporting = [
    {
      name: 'Bounced',
      value: overalAllStats ? overalAllStats?.awsBouncedEmails : 0,
      color: '#5C61F2',
    },
    {
      name: 'Verified',
      value: overalAllStats ? overalAllStats?.awsVerifiedLeads : 0,
      color: '#00C1FE',
    },
  ];
  
  const systemStats = [
    {
      name: 'Verified Leads',
      value: systemReport ? systemReport?.verifiedLeads : 0,
      color: '#9EC97F',
    },
    {
      name: 'Bounce',
      value: systemReport ? systemReport?.bouncedLeads : 0,
      color: '#FF6060',
    },
  ];

  // Fetch overall person stats
  const getPersonStats = useCallback(() => {
    try {
      Get(
        {
          user_id: selectedUser,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
          country_id: selectedCountry?.id,
          sector_id: selectedSector?.id,
          industry: selectedIndustry,
        },
        API_URLS.getPersonWiseLeadsStats,
        (resp) => {
          setOveralAllStats(resp?.data);
        },
        (error) => {
          // enqueueSnackbar('Failed to get the team stats', { variant: 'error' });
        }
      );
    } catch (error) {
      // enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [enqueueSnackbar, selectedUser, formattedDates, selectedCountry, selectedSector, selectedIndustry]);

  useEffect(() => {
    getPersonStats();
  }, [getPersonStats]);

  // Fetch users based on role
  const getRoleBasedUsers = useCallback(() => {
    try {
      Get(
        { role: 'leadExpert' },
        API_URLS.getRoleBasedUsers,
        (response) => {
          setUsers(response?.data?.roleBaseUsers);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch {
      // enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getRoleBasedUsers();
  }, [getRoleBasedUsers]);

  // // Debounced function to fetch user-based stats
  // const fetchUserBasedStats = useCallback(
  //   _.debounce(() => {
  //     try {
  //       Get(
  //         {
  //           user_id: selectedUser,
  //           from_date: formattedDates[0],
  //           to_date: formattedDates[1],
  //           country_id: selectedCountry?.id,
  //           sector_id: selectedSector?.id,
  //           industry: selectedIndustry,
  //         },
  //         API_URLS.getOverallAndUserBasedStats,
  //         (resp) => {
  //           setUserBasedStatsEmailFinder(resp?.data);
  //         },
  //         (error) => {
  //           // enqueueSnackbar('Failed to get the team stats', { variant: 'error' });
  //         }
  //       );
  //     } catch (error) {
  //       // enqueueSnackbar('Something went wrong', { variant: 'error' });
  //     }
  //   }, 300), // 300ms debounce time
  //   [selectedUser, formattedDates, enqueueSnackbar, selectedCountry, selectedSector, selectedIndustry]
  // );

  // // Effect to trigger debounced API call
  // useEffect(() => {
  //   fetchUserBasedStats();
  // }, [fetchUserBasedStats]);

  // const getUserSystemReport = useCallback(() => {
  //   try {
  //     Get(
  //       {
  //         user_id: selectedUser,
  //         from_date: formattedDates[0],
  //         to_date: formattedDates[1],
  //       },
  //       API_URLS.userSystemReport,
  //       (resp) => {
  //         setSystemReport(resp?.data);
  //       },
  //       (error) => {
  //         // enqueueSnackbar('Failed to get the system report', { variant: 'error' });
  //       }
  //     );
  //   } catch (error) {
  //     // enqueueSnackbar('Something went wrong', { variant: 'error' });
  //   }
  // }, [enqueueSnackbar, selectedUser, formattedDates]);

  // useEffect(() => {
  //   getUserSystemReport();
  // }, [getUserSystemReport]);

  const getAllCountries = () => {
    try {
      Get(
        {},
        API_URLS.getAllCountries,
        (response) => {
          setCountires(response?.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (response) => {
          setSectors(response.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);

  // const getIndustries = () => {
  //   try {
  //     Get(
  //       {},
  //       API_URLS.getUniqueIndustriesFromPersons,
  //       (response) => {
  //         console.log('response', response);
  //         setIndustries(response?.data?.industries);
  //       },
  //       (error) => {
  //         console.log('error', error);
  //       }
  //     );
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // useEffect(() => {
  //   getIndustries();
  // }, []);

  // Debounced function to fetch user-based stats
  const fetchCountryWiseStats = useCallback(
    _.debounce(() => {
      try {
        Get(
          {
            country_id: selectedCountry?.id,
            sector_id: selectedSector?.id,
            industry: selectedIndustry,
            from_date: formattedDates[0],
            to_date: formattedDates[1],
            user_id: selectedUser,
          },
          API_URLS.getCountryAndSectorWiseLeadsStats,
          (resp) => {
            setDynamicStats(resp?.data);
          },
          (error) => {
            // enqueueSnackbar('Failed to get the stats', { variant: 'error' });
          }
        );
      } catch (error) {
        // enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }, 300), // 300ms debounce time
    [selectedUser, formattedDates, enqueueSnackbar, selectedCountry, selectedSector, selectedIndustry]
  );

  // Effect to trigger debounced API call
  useEffect(() => {
    fetchCountryWiseStats();
  }, [fetchCountryWiseStats]);

  const dynamicChartData = [
    {
      name: 'With Contact Info',
      value: dynamicStats ? dynamicStats?.withContactInfo : 0,
      color: '#4dd2fe',
    },
    {
      name: 'Without Contact Info',
      value: dynamicStats ? dynamicStats?.withoutContactInfo : 0,
      color: '#ca80fe',
    },
  ];

  return (
    <div style={{ backgroundColor: '#ECF6FE' }}>
      <div>
        <Box display="flex" alignItems="center" marginLeft="5px" marginTop="5px" marginBottom="10px" width="100%">
          <Button
            size="large"
            sx={{
              backgroundColor: 'white',
              outline: 'grey',
              flexGrow: 1, // Makes the button expand to fill available space
              borderRadius: '0px',
              borderLeft: '1px solid #D8D8D8',
              borderRight: '1px solid #D8D8D8',
              borderBottom: '1px solid #D8D8D8',
              height: '60px',
              borderTop: showLeadsReportingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
              '&:hover': {
                borderTop: showLeadsReportingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
                backgroundColor: 'white',
                borderLeft: '1px solid #D8D8D8',
                borderRight: '1px solid #D8D8D8',
                borderBottom: '1px solid #D8D8D8',
              },
            }}
            onClick={() => {
              setShowLeadsReportingPage(true);
              setShowTeamReportingPage(false);
              setShowScrapperReportingPage(false);
              setShowEmailSendingReportingPage(false);
            }}
          >
            Leads Reporting
          </Button>

          <Button
            size="large"
            sx={{
              backgroundColor: 'white',
              outline: 'grey',
              flexGrow: 1, // Makes the button expand to fill available space
              borderRadius: '0px',
              borderLeft: '1px solid #D8D8D8',
              borderRight: '1px solid #D8D8D8',
              borderBottom: '1px solid #D8D8D8',
              height: '60px',
              borderTop: showTeamReportingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
              '&:hover': {
                borderTop: showTeamReportingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
                backgroundColor: 'white',
                borderLeft: '1px solid #D8D8D8',
                borderRight: '1px solid #D8D8D8',
                borderBottom: '1px solid #D8D8D8',
              },
            }}
            onClick={() => {
              setShowLeadsReportingPage(false);
              setShowTeamReportingPage(true);
              setShowScrapperReportingPage(false);
              setShowEmailSendingReportingPage(false);
            }}
          >
            Teams Reporting
          </Button>

          <Button
            size="large"
            sx={{
              backgroundColor: 'white',
              outline: 'grey',
              flexGrow: 1, // Makes the button expand to fill available space
              borderRadius: '0px',
              borderLeft: '1px solid #D8D8D8',
              borderRight: '1px solid #D8D8D8',
              borderBottom: '1px solid #D8D8D8',
              height: '60px',
              borderTop: showScrapperReprotingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
              '&:hover': {
                borderTop: showScrapperReprotingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
                backgroundColor: 'white',
                borderLeft: '1px solid #D8D8D8',
                borderRight: '1px solid #D8D8D8',
                borderBottom: '1px solid #D8D8D8',
              },
            }}
            onClick={() => {
              setShowLeadsReportingPage(false);
              setShowTeamReportingPage(false);
              setShowScrapperReportingPage(true);
              setShowEmailSendingReportingPage(false);
            }}
          >
            Scrapper Reporting
          </Button>

          <Button
            size="large"
            sx={{
              backgroundColor: 'white',
              outline: 'grey',
              flexGrow: 1, // Makes the button expand to fill available space
              borderRadius: '0px',
              borderLeft: '1px solid #D8D8D8',
              borderRight: '1px solid #D8D8D8',
              borderBottom: '1px solid #D8D8D8',
              height: '60px',
              borderTop: showEmailSendingReportingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
              '&:hover': {
                borderTop: showEmailSendingReportingPage ? '7px solid #00C1FE' : '7px solid #C1E6F1',
                backgroundColor: 'white',
                borderLeft: '1px solid #D8D8D8',
                borderRight: '1px solid #D8D8D8',
                borderBottom: '1px solid #D8D8D8',
              },
            }}
            onClick={() => {
              setShowLeadsReportingPage(false);
              setShowTeamReportingPage(false);
              setShowScrapperReportingPage(false);
              setShowEmailSendingReportingPage(true);
            }}
          >
            Email Sending Reporting
          </Button>
        </Box>
      </div>
      {!showEmailSendingReportingPage && (
        <>
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={6} md={2}>
              {/* <label htmlFor="outlined-textfield" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            Select Country
          </label> */}
              <Autocomplete
                fullWidth
                size="normal"
                options={countires}
                value={selectedCountry}
                getOptionLabel={(option) => option.country}
                onChange={(event, newCountry) => {
                  if (newCountry) {
                    setSelectedCountry(newCountry);
                  } else {
                    setSelectedCountry(null);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} placeholder="Select Country" />}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  marginLeft: '5px',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              {/* <label htmlFor="sector_id" style={{ fontWeight: 'bold' }}>
            Select Sector
          </label> */}
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option.sector}
                onChange={(event, newSector) => {
                  if (newSector) {
                    setSelectedSector(newSector);
                  } else {
                    setSelectedSector(null);
                  }
                }}
                value={selectedSector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sector"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginLeft: '5px',
                      paddingRight: '5px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            {!showScrapperReprotingPage && (
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={users}
                  size="normal"
                  getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select User"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {/* <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                options={industries}
                size="normal"
                getOptionLabel={(option) => option?.industry}
                onChange={(event, newValue) => {
                  setSelectedIndustry(newValue ? newValue?.industry : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Industry"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                options={status}
                size="normal"
                getOptionLabel={(option) => option?.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Status"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={2}>
              <MultiDatePicker
                onChange={(dateRange) => {
                  setFormattedDates(dateRange);
                }}
                numberOfMonths={2}
                height="54px"
                width="220px"
              />
            </Grid>
          </Grid>
        </>
      )}
      {showLeadsReportingPage && (
        <>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                mainLabel="Total Leads"
                leftLabel="With Emails"
                rightLabel="Without Emails"
                leftNumber={overalAllStats?.withEmailsCount}
                mainNumber={overalAllStats?.totalPersons}
                rightNumber={overalAllStats?.withoutEmailsCount}
                onLeftClick={() => {
                  setSelectedFilter('with');
                }}
                onRightClick={() => {
                  setSelectedFilter('without');
                }}
                onMainClick={() => {
                  setSelectedFilter('');
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                mainLabel="With Emails"
                leftLabel="Checked"
                rightLabel="Unchecked"
                leftNumber={overalAllStats?.checkedEmailsCount}
                mainNumber={overalAllStats?.withEmailsCount}
                rightNumber={overalAllStats?.unCheckedEmailsCount}
                onLeftClick={() => {
                  setSelectedFilter('checked');
                }}
                onRightClick={() => {
                  setSelectedFilter('unchecked');
                }}
                onMainClick={() => {
                  setSelectedFilter('with');
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                mainLabel="Checked"
                leftLabel="Verified"
                rightLabel="Bounced"
                leftNumber={overalAllStats?.verifiedEmailsCount}
                mainNumber={overalAllStats?.checkedEmailsCount}
                rightNumber={overalAllStats?.bouncedEmailsCount}
                onLeftClick={() => {
                  setSelectedFilter('verified');
                }}
                onRightClick={() => {
                  setSelectedFilter('bounced');
                }}
                onMainClick={() => {
                  setSelectedFilter('checked');
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                mainLabel="AWS Check"
                leftLabel="Verified"
                rightLabel="Bounced"
                leftNumber={overalAllStats?.awsVerifiedLeads}
                mainNumber={overalAllStats?.awsTotal}
                rightNumber={overalAllStats?.awsBouncedEmails}
                onLeftClick={() => {
                  setSelectedFilter('awsverified');
                }}
                onRightClick={() => {
                  setSelectedFilter('awsbounced');
                }}
                onMainClick={() => {
                  setSelectedFilter('awstotal');
                }}
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <ClickablePieChart
                title="Leads reporting"
                subheader={`Total Leads: ${dynamicStats ? dynamicStats?.totalPersons : 0}`}
                chartData={dynamicChartData}
                dynamicStats={dynamicStats}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HighchartsPieChart
                title="With Email Reporting"
                chartData={withEmailReporting}
                subheader={`Total : ${overalAllStats ? overalAllStats?.withEmailsCount : 0}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HighchartsPieChart
                title="Checked Email Reporting"
                chartData={checkedEmailReporting}
                subheader={`Total : ${overalAllStats ? overalAllStats?.checkedEmailsCount : 0}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HighchartsPieChart
                title="Amazon Checked Email Reporting"
                chartData={awsEmailReporting}
                subheader={`Total : ${overalAllStats ? overalAllStats?.awsTotal : 0}`}
              />
            </Grid>
          </Grid>
          <br />
          {showLeadsReportingPage && (
            <PersonsTableReporting
              selectedUser={selectedUser}
              selectedCountry={selectedCountry}
              selectedSector={selectedSector}
              selectedIndustry={selectedIndustry}
              formattedDates={formattedDates}
              selectedFilter={selectedFilter}
            />
          )}
        </>
      )}
      {showTeamReportingPage && (
        <TeamReportingPage
          selectedUser={selectedUser}
          formattedDates={formattedDates}
          country={selectedCountry}
          sector={selectedSector}
        />
      )}
      {showScrapperReprotingPage && (
        <ScrapperReportingPage
          from_date={formattedDates[0]}
          to_date={formattedDates[1]}
          industry={selectedIndustry}
          country_id={selectedCountry}
          sector_id={selectedSector}
        />
      )}
      {showEmailSendingReportingPage && <EmailSendingReportingPage />}

      {/* <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={8}>
          <HighchartsPieChart
            title="Email Finder Team Report"
            subheader={`Total Leads: ${userBasedStatsEmailFinder ? userBasedStatsEmailFinder?.totalPersons : 0}`}
            chartData={chartData}
            showDropdown={false}
            users={users}
            selectedUser={(user) => {
              console.log('Selected User:', user);
              setSelectedUser(user);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <HighchartsPieChart title="Email Finder System Report" chartData={systemStats} />
        </Grid>
      </Grid> */}

      <br />

      {/* <EmailSendingPiChart /> */}
      <br />
      {/* 
      <EmailBasedReporting users={users} /> */}
      {/* <div
        style={{
          marginLeft: '5px',
        }}
      >
        <MaterialReactTable
          enableStickyHeader
          enableTopToolbar={false}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          enableColumnActions={false}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error Loading Data',
                }
              : undefined
          }
          columns={userReportColumns}
          data={userWorkReport}
          positionToolbarAlertBanner="bottom"
          manualPagination
          rowCount={totalCount}
          onPaginationChange={setPagination}
          manualFiltering
          onGlobalFilterChange={setSearchString}
          state={{
            pagination,
            isLoading: loadingData,
          }}
        />
      </div> */}
    </div>
  );
}

export default AdminDashboard;
