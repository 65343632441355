import React, { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import MaterialReactTable from 'material-react-table';
import { Box, Button, Typography } from '@mui/material';

function CandidatesList() {
  const userId = parseInt(localStorage.getItem('userId'), 10);
  const [candidates, setCandidates] = useState([]);

  // Fetch candidates from API
  const getCandidates = useCallback(() => {
    Get(
      { user_id: userId },
      API_URLS.getDailyCandidates,
      (response) => setCandidates(response.data),
      (error) => console.error(error)
    );
  }, [userId]);

  useEffect(() => {
    getCandidates();
  }, [getCandidates]);

  // Define columns for MRT
  const columns = [
    {
      accessorKey: 'personal_email', // Corresponds to the data field
      header: 'Email',
      size: 250,
    },
    {
      accessorKey: 'full_name',
      header: 'Full Name',
      size: 200,
    },
    {
      accessorKey: 'current_title',
      header: 'Current Title',
      size: 200,
    },
    {
      accessorKey: 'location',
      header: 'Location',
      size: 150,
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Candidates List
      </Typography>
      <Button onClick={getCandidates} variant="contained" color="primary">
        Get Candidates
      </Button>
      <MaterialReactTable
        columns={columns}
        data={candidates}
        enablePagination
        enableSorting
        enableColumnResizing
        // muiTableProps={{
        //   sx: { minHeight: 400 },
        // }}
        initialState={{ pagination: { pageSize: 5 } }}
      />
    </Box>
  );
}

export default CandidatesList;
