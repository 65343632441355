import { Icon } from '@iconify/react';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function ShowCandidateDetailsPage(props) {
  const [toggleInfo, setToggleInfo] = useState('cv');
  const navigate = useNavigate();
  const { roleId } = useParams();
  const cellStyle = {
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold',
  };
  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <Box
        display="flex"
        sx={{ backgroundColor: '#D6F4FB', height: '80px' }}
        justifyContent="space-between"
        alignItems="center"
        padding="0 20px"
      >
        {/* Icon and Back Arrow */}
        <Icon
          icon="material-symbols:arrow-back"
          style={{ fontSize: '22px', cursor: 'pointer' }}
          onClick={() => navigate(`/resourcing/candidates/${roleId}`)}
        />

        {/* Client No */}
        <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            Client No:
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>345</Typography>
        </Box>

        {/* Role Name */}
        <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            Role Title:
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>UI UX Designer</Typography>
        </Box>

        {/* Status */}
        <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            Status:
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>In Progress</Typography>
        </Box>

        {/* LIs */}
        <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            LIs:
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>30</Typography>
        </Box>

        {/* CVs */}
        <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            CVs:
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>30</Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" sx={{ backgroundColor: 'white', height: 'auto' }}>
        <Box display="flex" justifyContent="space-between">
          <p style={{ fontSize: '1.3rem', paddingLeft: '20px', paddingTop: '20px', fontWeight: 'bold' }}>
            Miroslawa Chatys: UX/UI Designer-volunteer-customer service
          </p>
          <Button
            sx={{
              border: '2px solid #00C1FE',
              padding: '5px 10px',
              color: '#00C1FE',
              width: '100px',
              marginTop: '20px',
              marginRight: '20px',
            }}
          >
            Add CV
          </Button>
        </Box>

        <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
          <Icon icon="ic:outline-email" style={{ fontSize: '18px' }} />
          <p style={{ fontSize: '16px', marginLeft: '5px', color: '00C1FE' }}> mirosalwa@yahoo.com </p>
        </Box>
        <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
          <Icon icon="flowbite:mobile-phone-outline" style={{ fontSize: '18px' }} />
          <p style={{ fontSize: '16px', marginLeft: '5px' }}> +44 -0745405454</p>
        </Box>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <Box>
          <ToggleButtonGroup
            value={toggleInfo}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                setToggleInfo(value);
              }
            }}
            aria-label="company sections"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: '20px',
              marginBottom: '10px', // Add margin bottom for spacing
              paddingLeft: '20px',
              paddingTop: '20px',
            }}
          >
            <ToggleButton
              value="cv"
              aria-label="cv"
              style={{
                border: 'none',
                color: toggleInfo === 'cv' ? '#000000' : '#b6afaf',
                borderBottom: toggleInfo === 'cv' ? '3px solid #000000' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                backgroundColor: 'white',
                fontSize: '18px',
                fontWeight: 'normal',
              }}
            >
              CV
            </ToggleButton>
            <ToggleButton
              value="activity"
              aria-label="activity"
              style={{
                border: 'none',
                color: toggleInfo === 'activity' ? '#000000' : '#b6afaf',
                borderBottom: toggleInfo === 'activity' ? '3px solid #000000' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '18px',
                fontWeight: 'normal',
              }}
            >
              Activity
            </ToggleButton>
            <ToggleButton
              value="experience"
              aria-label="experience"
              style={{
                border: 'none',
                color: toggleInfo === 'experience' ? '#000000' : '#b6afaf',
                borderBottom: toggleInfo === 'experience' ? '3px solid #000000' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '18px',
                fontWeight: 'normal',
              }}
            >
              Experience
            </ToggleButton>
          </ToggleButtonGroup>
          <hr
            style={{
              width: '20%',
              marginLeft: '20px',
              color: '#CAC5C5',
              border: 'none',
              borderTop: '1px solid #CAC5C5',
              marginTop: '-10px',
            }}
          />

          <>
            {toggleInfo === 'cv' && (
              <>
                <p
                  style={{
                    fontSize: '26px',
                    marginLeft: '20px',
                    textTransform: 'capitalize',
                    marginTop: '25px',
                    color: '#A90B0B',
                    fontWeight: 'bold',
                    letterSpacing: '4px',
                  }}
                >
                  MIROSLAWA CHATYS
                </p>
                <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" width="80%">
                  <p style={{ fontSize: '18px', marginLeft: '5px' }}> mirosalwa@yahoo.com </p>
                  <p style={{ fontSize: '18px', marginLeft: '5px' }}> +44 77774787584 </p>
                </Box>
                <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                  <p
                    style={{
                      fontSize: '22px',
                      textTransform: 'capitalize',
                      color: '#A90B0B',
                      fontWeight: 'bold',
                      letterSpacing: '4px',
                    }}
                  >
                    SUMMARY
                  </p>
                  <p style={{ fontSize: '18px', marginTop: '5px' }}>
                    {' '}
                    Experienced UI/UX Designer with a proven track record in designing user interfaces for various
                    industries. Specializes in utilizing user research methods to inform design decisions and optimize
                    user experience. Capable of effectively collaborating with diverse teams to create innovative UI/UX
                    solutions.{' '}
                  </p>
                </Box>
                <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                  <p
                    style={{
                      fontSize: '22px',
                      textTransform: 'capitalize',
                      color: '#A90B0B',
                      fontWeight: 'bold',
                      letterSpacing: '4px',
                    }}
                  >
                    WORK EXPERIENCES
                  </p>
                  <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>
                    UX DESIGNER / CUSTOMER SERVICES - VOLUNTEER
                  </p>
                  <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>01/2019 - PRESENT</p>
                  <p style={{ fontSize: '18px', marginTop: '5px', marginLeft: '10px' }}>
                    Strategically utilize various user research methods , such as customer journey mapping , user
                    interviews , and user testing , to provide valuable insights that inform design decisions and
                    optimize.
                  </p>
                </Box>
                <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                  <p
                    style={{
                      fontSize: '22px',
                      textTransform: 'capitalize',
                      color: '#A90B0B',
                      fontWeight: 'bold',
                      letterSpacing: '4px',
                    }}
                  >
                    EDUCATION
                  </p>
                  <p style={{ fontSize: '18px', marginTop: '5px' }}>HNC - DIPLOMA TECHNINAL COMPUTER SCIENCE</p>
                </Box>
              </>
            )}
            {toggleInfo === 'activity' && (
              <>
                <Box display="flex" marginTop="20px" marginLeft="20px" flexDirection="column">
                  {/* create an un ordered list with 3 items */}
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    <li style={{ fontWeight: 'bold', fontSize: '18px' }}>Registered 6 years ago</li>
                    <li style={{ fontWeight: 'bold', fontSize: '18px' }}>Last active 10 days ago</li>
                    <li style={{ fontWeight: 'bold', fontSize: '18px' }}>1 Job application in the last 1 month</li>
                  </ul>
                  <div
                    style={{
                      maxHeight: '200px', // Approximate height for 3 rows
                      overflowY: 'auto',
                      border: '1px solid #ddd',
                      marginTop: '20px',
                      border: '1px solid #EBEBEB',
                      marginRight: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        fontFamily: 'Arial, sans-serif',
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            position: 'sticky',
                            top: 0,
                            textAlign: 'left',
                            padding: '14px',
                            fontSize: '14px',
                            backgroundColor: '#EBEBEB',
                          }}
                        >
                          <th style={cellStyle}>Job Title</th>
                          <th style={cellStyle}>Locations</th>
                          <th style={cellStyle}>Date Applied</th>
                        </tr>
                        <tr
                          style={{
                            textAlign: 'left',
                            padding: '14px',
                            fontSize: '14px',
                          }}
                        >
                          <td style={cellStyle}>Freelance UX/UI Designer</td>
                          <td style={cellStyle}>South West London (SW1)</td>
                          <td style={cellStyle}> 01 Sep 2024</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Box>
              </>
            )}
            {toggleInfo === 'experience' && (
              <>
                <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                  <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>Ambassador</p>
                  <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>Jan 2023 - Current</p>
                  <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>
                    HOPE program by ACTION AID and KARIOS to fight against irregularmigration - Auchi
                  </p>
                </Box>
                <br />
              </>
            )}
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />

            <p style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold', marginTop: '15px' }}>Curent Status</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="carbon:location" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> UK </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="uil:suitcase-alt" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> UI/UX Designer-volunteer-customer-services </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="fluent-mdl2:money" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> $55,000 to $59,999 per anum </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="fluent-mdl2:office-chat" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}>English </p>
            </Box>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />

            <p style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold', marginTop: '15px' }}>Desired role</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="carbon:location" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> UK </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="uil:suitcase-alt" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> UI/UX Designer-volunteer-customer-services </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="fluent-mdl2:money" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> Salary not supplied </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="solar:suitcase-line-duotone" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> Job Level not supplied </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="carbon:time" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> 1 week </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="solar:calendar-outline" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> Graduation year not supplied </p>
            </Box>
            <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
              <Icon icon="ph:car-thin" style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '18px', marginLeft: '5px' }}> Has diriving license </p>
            </Box>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />

            <p style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold', marginTop: '15px' }}>Key Skills</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <p style={{ fontSize: '18px', marginTop: '5px', marginLeft: '20px' }}>
              {' '}
              Experienced UI/UX Designer with a proven track record in designing user interfaces for various industries.
              Specializes in utilizing user research methods to inform design decisions and optimize user experience.
              Capable of effectively collaborating with diverse teams to create innovative UI/UX solutions.{' '}
            </p>
            <br />
          </>
        </Box>
      </Box>
    </div>
  );
}

export default ShowCandidateDetailsPage;
