import { Icon } from '@iconify/react';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';
import { LI_Scrapper_URLs } from 'src/constants/LIScrapperURL';

function AddLIComponent() {
  const navigate = useNavigate();
  const [openLIAddDialog, setOpenLIAddDialog] = useState(false);
  const [submittedRows, setSubmittedRows] = useState();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');
  const [scrappedData, setScrappedData] = useState();
  const [rows, setRows] = useState([
    { liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '', isSubmitted: false },
    { liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '', isSubmitted: false },
    { liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '', isSubmitted: false },
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  // const handleSendLIToScrapper = (index) => {
  //   const rowData = rows[index];
  //   const li = rowData?.liLink 
  //   if(!li){
  //     enqueueSnackbar('Please enter LI link', { variant: 'error' });
  //     return 
  //   }

  //   try {
  //     Post(
  //       { url  : li },
  //       LI_Scrapper_URLs.scrapData,
  //       (resp) => {
  //         enqueueSnackbar('Response reeived from scrapper');
  //         setScrappedData(resp);
  //         handleSubmitRow(resp)
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
      
  //   }
  // }
  const handleSubmitRow = (index) => {
    const rowData = rows[index];
    const li = rowData?.liLink;
    // match Person profile LI pattern
    const pattern = /https:\/\/www.linkedin.com\/in\/[a-zA-Z0-9-]+/g;
    if (!li) {
      enqueueSnackbar('Please enter LI link', { variant: 'error' });
      return;
    }
    if (!pattern.test(li)) {
      enqueueSnackbar('Please enter valid LI link', { variant: 'error' });
      return;
    }
    const personalNumber = rowData?.personalNumber;
    const businessNumber = rowData?.businessNumber;
    const personalEmail = rowData?.personalEmail;
    const businessEmail = rowData?.businessEmail;
    // match email pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!personalEmail && personalEmail !== '') {
      enqueueSnackbar('Please enter personal email', { variant: 'error' });
      return;
    }
    if (personalEmail !== '' && !emailPattern.test(personalEmail)) {
      enqueueSnackbar('Please enter valid personal email', { variant: 'error' });
      return;
    }
    if (businessEmail !== '' && !businessEmail) {
      enqueueSnackbar('Please enter business email', { variant: 'error' });
      return;
    }

    if (businessEmail !== '' && !emailPattern.test(businessEmail)) {
      enqueueSnackbar('Please enter valid business email', { variant: 'error' });
      return;
    }

    const payload = {
      profile_link: li,
      personal_contact: personalNumber,
      business_number: businessNumber,
      personal_email: personalEmail,
      business_email: businessEmail,
      user_id: parseInt(userId),
      profile_status: 'pending',
      role_id: parseInt(roleId),
    };
    try {
      Post(
        payload,
        API_URLS.addLIToRole,
        (resp) => {
          enqueueSnackbar('LI added successfully', { variant: 'success' });
          // update isSubmitted flag
          const updatedRows = [...rows];
          updatedRows[index].isSubmitted = true;
          setRows(updatedRows);
        },
        (error) => {
          enqueueSnackbar('Error adding LI', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error adding LI', { variant: 'error' });
    }
  };

  const addMoreRows = () => {
    const newRows = [
      { liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
      { liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
      { liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
    ];
    setRows([...rows, ...newRows]);
  };

  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <Box display="flex" marginBottom="10px" alignItems="center">
        <Icon
          icon="ion:arrow-back"
          style={{ fontSize: '24px', cursor: 'pointer', color: '#00C1FE' }}
          onClick={() => navigate(`/resourcing/roles-processing/${roleId}`)}
        />
        <p style={{ fontSize: '18px', marginLeft: '5px', color: '#00C1FE', fontWeight: 'bold' }}>Back to Role Detail</p>
      </Box>
      <table style={{ width: '100%', backgroundColor: 'white', borderCollapse: 'collapse', borderRadius: '20px' }}>
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '15px',
                borderLeft: 'none',
                color: '#979797',
                borderTop: 'none',
              }}
            >
              S.No
            </th>
            <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
              LI Link
            </th>
            <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
              Personal Number
            </th>
            <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
              Business Number
            </th>
            <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
              Personal Email
            </th>
            <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
              Business Email
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '15px',
                borderRight: 'none',
                color: '#979797',
                borderTop: 'none',
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index} style={index === rows.length - 1 ? { borderBottom: 'none' } : {}}>
              <td
                style={{
                  border: '1px solid #D8D8D8',
                  padding: '10px',
                  textAlign: 'center',
                  borderLeft: 'none',
                  color: '#979797',
                }}
              >
                {index + 1}
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
                <input
                  type="text"
                  name="liLink"
                  value={row.liLink}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{ width: '100%', border: 'none', outline: 'none' }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
                <input
                  type="text"
                  name="personalNumber"
                  value={row.personalNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{ width: '100%', border: 'none', outline: 'none' }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
                <input
                  type="text"
                  name="businessNumber"
                  value={row.businessNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{ width: '100%', border: 'none', outline: 'none' }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
                <input
                  type="email"
                  name="personalEmail"
                  value={row.personalEmail}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{ width: '100%', border: 'none', outline: 'none' }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
                <input
                  type="email"
                  name="businessEmail"
                  value={row.businessEmail}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{ width: '100%', border: 'none', outline: 'none' }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', borderRight: 'none' }}>
                <Box display="flex" justifyContent="center">
                  <Button
                    disabled={row.isSubmitted}
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      width: '90px',
                      height: '35px',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: '#00C1FE',
                        color: '#fff',
                      },
                      borderRadius: '5px',
                      padding: '2px',
                    }}
                    onClick={() => {
                      // handleSubmitRow(index);
                      setSubmittedRows(index);
                      // setOpenLIAddDialog(true);
                      handleSubmitRow(index);
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box display="flex" justifyContent="flex-end" marginTop="1rem">
        <Button
          sx={{
            background: '#00C1FE',
            color: '#fff',
            width: '150px',
            height: '35px',
            marginRight: '1rem',
            border: '1px solid #00C1FE',
            '&:hover': {
              background: '#00C1FE',
              color: '#fff',
            },
            borderRadius: '5px',
            padding: '10px',
          }}
          onClick={addMoreRows}
          startIcon={<Add />}
        >
          Add more rows
        </Button>
      </Box>
      {openLIAddDialog && (
        <ActionConfirmationDialog
          open={openLIAddDialog}
          onClose={() => setOpenLIAddDialog(false)}
          title={`Are you sure you want to submit LI`}
          actionButtonText="Yes"
          onSubmit={() => handleSubmitRow()}
        />
      )}
    </div>
  );
}

export default AddLIComponent;
