import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSpring, animated } from 'react-spring';

const ArrowPipeline = ({
  title,
  total,
  left,
  center,
  right,
  onClick,
  titleService,
  leftTooltip,
  centerTooltip,
  rightTooltip,
  color,
  onLeftClick,
  onCenterClick,
  onRightClick,
}) => {
  const useStyles = makeStyles({
    concaveBox: {
      position: 'relative',
      width: '350px',
      height: '90px',
      backgroundColor: '#d1f3fa',
      clipPath: 'polygon(10% 10%, 90% 10%, 100% 50%, 90% 90%, 10% 90%, 20% 50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '10px',
      cursor: 'pointer',
      transition: 'box-shadow 0.3s ease', // Smooth transition for shadow effect
      boxShadow: '0 0 0 4px blue', // Border effect using box-shadow when active
      borderBottom: `13px solid ${color}`,
      // borderTop: '13px solid red',
      // borderLeft: '13px solid red',
    },
    title: {
      marginLeft: '10px',
      marginTop: '5px',
      fontWeight: 'bold',
      paddingTop: '10px',
      justifyContent: 'end',
    },
    stats: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      width: '40%',
      marginLeft: '10%',
      marginBottom: '5px',
    },
  });

  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  // Animations for statistics
  const leftAnimation = useSpring({ number: isLoaded ? left : 0 });
  const centerAnimation = useSpring({ number: isLoaded ? center : 0 });
  const rightAnimation = useSpring({ number: isLoaded ? right : 0 });

  return (
    <Box className={classes.concaveBox}>
      <Typography variant="body2" className={classes.title} onClick={onClick} sx={{ paddingTop: '5px' }}>
        {title}{' '}
        {total && (
          <Tooltip title={titleService} placement="bottom" arrow>
            <span variant="body1" style={{ color: '#2711EE' }}>
              ({total > 0 ? total : 0})
            </span>
          </Tooltip>
        )}
      </Typography>
      <div className={classes.stats}>
        <div onClick={() => onLeftClick(title)} style={{ cursor: 'pointer', padding: '8px' }}>
          <Tooltip title={leftTooltip} placement="bottom" arrow>
            <animated.div style={{ color: '#2711EE' }}>
              {leftAnimation?.number.to((value) => Math.floor(value))}
            </animated.div>
          </Tooltip>
        </div>
        <div onClick={() => onCenterClick(title)} style={{ cursor: 'pointer', padding: '8px' }}>
          <Tooltip title={centerTooltip} placement="bottom" arrow>
            <animated.div style={{ fontWeight: 'bold' }}>
              {centerAnimation?.number.to((value) => Math.floor(value))}
            </animated.div>
          </Tooltip>
        </div>
        <div onClick={() => onRightClick(title)} style={{ cursor: 'pointer', padding: '8px' }}>
          <Tooltip title={rightTooltip} placement="bottom" arrow>
            <animated.div style={{ color: '#2711EE' }}>
              {rightAnimation?.number.to((value) => Math.floor(value))}
            </animated.div>
          </Tooltip>
        </div>
      </div>
    </Box>
  );
};

export default ArrowPipeline;
