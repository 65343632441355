import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import CheckEmailForVerification from './pages/auth/CheckEmailForVerification';

import DashboardPage from './pages/DashboardPage';
import Page404 from './pages/Page404';
import VerifiedAccountPage from './pages/auth/AccountVerificationPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import { CompanyDetailsPage, CompanyListPage, MainLeadGenerationPage } from './pages/leads';
import ForgetPasswordPage from './pages/auth/ForgetPasswordPage';
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import CampaignsPage from './pages/admin/CampaignsPage';
import CampaignSettings from './pages/admin/CampaignSettings';
import TemplatesPage from './pages/admin/TemplatesPage';
import { ClientUnsubscriptionPage } from './pages/public/subscriptions';
import BlacklistPage from './pages/admin/BlacklistPage';
import { CandidateList } from './sections/candidates';
import { EmailResearcherPage } from './pages/leadFinder';
import UsersPage from './pages/admin/UsersPage';
import { ACMInvoicesPage, ACMManageClientsPage, ACMManageRoles, ACMPage } from './pages/acm';
import { CandidateSearchPage, RolesProcessing, ShowCandidateDetailsPage } from './sections/acm/components';
import { ResourcingCandidatePage, ResourcingRoles, SearchHistoryPage } from './pages/resourcing';
import { ResourcerDashboardPage, ResourcingRolesProcessing, RolesPage } from './sections/resourcing';
import { AddLIComponent, CVUploadComponent } from './sections/resourcing/components';
import ReedsSearchPage from './pages/admin/ReedsSearchPage';
import JobBoards from './pages/admin/JobBoards';
import { LimitSettingsPage } from './pages/settings/acm';
import { AllCandidates, Direct360Page } from './pages/direct360';
import CandidatesList from './sections/acm/candidates/CandidatesList';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    // Admin Routes

    {
      path: '/leadExpert',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/leadExpert/app" />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'leads', element: null },
        { path: 'findJobs', element: <MainLeadGenerationPage role="leadExpert" /> },
        { path: 'findEmails', element: <EmailResearcherPage /> },
      ],
    },
    // Admin Routes

    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/app" />, index: true }, // Default route for `/admin`
        { path: 'app', element: <AdminDashboardPage /> },
        { path: 'campaigns', element: <CampaignsPage /> },
        { path: 'create-campaign', element: <CampaignSettings /> },
        { path: 'templates', element: <TemplatesPage /> },
        { path: 'leads', element: <MainLeadGenerationPage role="admin" /> },
        { path: 'blacklist', element: <BlacklistPage /> },
        { path: 'candidates', element: <CandidateList /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'companies', element: <CompanyListPage /> },
        { path: 'company', element: <CompanyDetailsPage /> },
        { path: 'resources', element: <ResourcingCandidatePage /> },
        { path: 'jobboards', element: <JobBoards /> },
        { path: 'dataSearch', element: <ReedsSearchPage /> },
        { path: 'reeds', element: <ReedsSearchPage /> },
        { path: 'settings', element: <LimitSettingsPage /> },
      ],
    },

    {
      path: '/acm',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/acm/app" />, index: true },
        { path: 'app', element: <ACMPage /> },
        { path: 'invoices', element: <ACMInvoicesPage /> },
        { path: 'roles', element: <ACMManageRoles /> },
        { path: 'clients', element: <ACMManageClientsPage /> },
        { path: 'roles-processing/:roleId', element: <RolesProcessing /> },
        { path: 'candidates/:roleId', element: <CandidateSearchPage /> },
        { path: 'candidateDetail/:roleId/:candidateId', element: <ShowCandidateDetailsPage /> },
        { path: 'candidates', element: <CandidatesList /> },
      ],
    },
    {
      path: '/resourcing',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/resourcing/app" />, index: true },
        { path: 'app', element: <ResourcerDashboardPage /> },
        { path: 'roles', element: <ResourcingRoles /> },
        { path: 'roles-processing/:roleId', element: <ResourcingRolesProcessing /> },
        { path: 'candidates/:roleId', element: <CandidateSearchPage /> },
        { path: 'candidateDetail/:roleId/:candidateId', element: <ShowCandidateDetailsPage /> },
        { path: 'uploadCV/:roleId', element: <CVUploadComponent /> },
        { path: 'searchHistory', element: <SearchHistoryPage /> },
        { path: 'addLI/:roleId', element: <AddLIComponent /> },
      ],
    },
    {
      path: '/recruiter',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/recruiter/app" />, index: true },
        { path: 'app', element: <Direct360Page /> },
        { path: 'allCandidates/:roleId', element: <AllCandidates /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'verify/:verificationtoken',
      element: <VerifiedAccountPage />,
    },
    {
      path: 'verifyEmail',
      element: <CheckEmailForVerification />,
    },
    {
      path: 'forgetPassword',
      element: <ForgetPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'unsubscribe', element: <ClientUnsubscriptionPage /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
